const messages = {
  de: {
    pay_yearly_text1: "Jährlich",
    login_text6: "Laden Sie es herunter",
    login_text5: "um mehr zu tauschen",
    login_text4: "ChatUp KI",
    message_info2:
      "Bitte fügen Sie ein Gesichtsbild hinzu, um das Gesicht auszutauschen.",
    login_text3: "Herunterladen",
    message_info3: "Bitte wählen Sie ein Produkt zum Abonnieren aus",
    credits_not_enough:
      "Nicht genügend Credits, bitte kaufen Sie mehr Credits oder aktualisieren Sie Ihren Plan.",
    login_text9: "Google Play",
    message_info1: "Bitte fügen Sie zuerst ein Zielbild hinzu.",
    toast_timeout:
      "Der Server ist überlastet und der Face-Swap ist abgelaufen. Bitte versuchen Sie, ein kürzeres Video hochzuladen.",
    login_text8: "Laden Sie es herunter",
    login_text7: "App Store",
    select_img_toast_text: "Bitte wählen Sie ein Gesichtsbild aus.",
    pay_yearly_text9: "Nackt & NSFW",
    faceswap_text10: "Zielbild",
    pay_yearly_text7: "Hohe Qualität",
    pay_yearly_text8: "Kein Wasserzeichen",
    toast_wrong_load:
      "Fehler beim Laden des Videos. Bitte versuchen Sie es mit einer anderen Datei.",
    pay_text3: "75 % RABATT",
    pay_yearly_text5: "Credits",
    faceswap_text13: "Gesichtstausch",
    pay_text2: "Bis zu",
    pay_yearly_text6: "Tauschen Sie in Sekundenschnelle aus",
    pay_text1: "Holen Sie sich Premium",
    pay_yearly_text3: "Woche",
    faceswap_text11: "JPG / PNG",
    pay_yearly_text4: "pro Jahr abgerechnet",
    faceswap_text12: "Bilder, die Sie ausprobieren können",
    target_video_text: "Zielvideo",
    ultra_video_length: "Bis zu 1 Minute",
    pay_text4: "für das erste Abonnement.",
    login_text2: "Um unbegrenzten Face Swap zu genießen!",
    login_text1: "Anmelden",
    no_more_credits:
      "Es sind keine Credits mehr vorhanden. Bitte upgraden Sie Ihren Plan, um mehr zu erhalten.",
    target_video_duration_text: "Weniger als 6s",
    test: "Hallo Welt",
    videoswap_text7: "Video austauschen",
    loading_text1: "Geschätzte 30 Sekunden bis 5 Minuten",
    videoswap_text6: "Zielvideo hinzufügen",
    loading_text2:
      "Bitte bleiben Sie auf der aktuellen Seite, sonst geht das beste Ergebnis verloren.",
    videoswap_text9: "MP4 / MOV",
    loading_text3: "Beschleunigen",
    web_request_error:
      "Netzwerkfehler, bitte überprüfen Sie es und versuchen Sie es später erneut",
    toast_wrong_fromat:
      "Bitte laden Sie ein Video im MP4- oder MOV-Format hoch.",
    toast_wrong_size:
      "Bitte laden Sie ein Video hoch, das kleiner als 50 MB ist.",
    mobile_text2: "GROSSE VERKÄUFE",
    mobile_text1: "Beginnen Sie jetzt",
    add_video_first_text: "Bitte fügen Sie zuerst ein Zielvideo hinzu.",
    toast_wrong_duration:
      "Bitte laden Sie ein Video hoch, das kürzer als 1 Minute ist.",
    pay_ultra_video_swap_text6: "Ultra-Video-Swap",
    ultra_video_swap_text1: "Max. 50 MB, bis zu 1 Minute",
    video_ultra_text1: "Ultra",
    tab_video_text: "Video",
    videoswap_text12: "Videos, die Sie ausprobieren können",
    toast_1: "Bitte wählen Sie das Quellbild aus.",
    normal_video_swap_credits_text: "(2 Credits /Video)",
    toast_2:
      "Es ist ein Fehler aufgetreten. Bitte überprüfen Sie Ihr Netzwerk und versuchen Sie es erneut.",
    pay_weekly_text2: "Kredit",
    pay_weekly_text3: "Tauschen Sie in Sekundenschnelle aus",
    faceswap_value_text1: "Über 250 Credits",
    pay_weekly_text1: "Wöchentlich",
    pay_subscribe: "ABONNIEREN",
    faceswap_value_text3: "Kein Wasserzeichen",
    pay_monthly_text1: "Monatlich",
    faceswap_value_text2: "Warteschlange überspringen",
    faceswap_value_text5: "Hohe Qualität",
    faceswap_value_text4: "Keine Werbung",
    pay_monthly_text4: "Hohe Qualität",
    pay_monthly_text5: "Kein Wasserzeichen",
    faceswap_value_text6: "Keine Einschränkungen",
    pay_monthly_text2: "Credits",
    pay_monthly_text3: "Tauschen Sie in Sekundenschnelle aus",
    faceswap_text5: "Nimm dein Gesicht",
    faceswap_text4: "Gesichtsbild hinzufügen",
    pay_monthly_text6: "Nackt & NSFW",
    faceswap_text7: "Gesicht tauschen",
    faceswap_text6: "Zielbild hinzufügen",
    faceswap_text9: "JPG / PNG",
    faceswap_text8: "Gesichtsbild",
    normal_video_swap_text1: "Maximal 10 MB, bis zu 4 Sekunden",
    pay_weekly_text6: "Nackt & NSFW",
    pay_weekly_text4: "Hohe Qualität",
    ultra_video_swap_credits_text: "(10 Credits/Sekunde)",
    ultra_video_size: "Maximal 50 MB",
    ultra_high_quality: "Hohe Qualität",
    pay_weekly_text5: "Kein Wasserzeichen",
    faceswap_text1: "Hohe Qualität",
    faceswap_value_button_text1: "Upgrade",
    faceswap_text3: "Anmelden",
    faceswap_text2: "Credits",
    pay_bottom_text1: "Sicherheit Geschützt durch",
    result_text: "Ergebnis",
    pay_bottom_text2: "ChatUp KI",
    pay_bottom_text3: "Jederzeit kündbar",
    tab_image_text: "Bild",
  },
  no: {
    pay_yearly_text1: "Årlig",
    login_text6: "Last ned PÅ",
    login_text5: "for å bytte mer",
    login_text4: "ChatUp AI",
    message_info2: "Legg til et ansiktsbilde for å bytte ansikt.",
    login_text3: "Last ned",
    message_info3: "Velg et produkt for å abonnere",
    credits_not_enough:
      "Ikke nok kreditter, vennligst kjøp flere kreditter eller oppgrader planen din.",
    login_text9: "Google Play",
    message_info1: "Vennligst legg til et målbilde først.",
    toast_timeout:
      "Serveren er overbelastet og ansiktsbyttet ble tidsavbrutt. Prøv å laste opp en kortere video.",
    login_text8: "Last ned PÅ",
    login_text7: "App Store",
    select_img_toast_text: "Velg et ansiktsbilde.",
    pay_yearly_text9: "Naken og NSFW",
    faceswap_text10: "Målbilde",
    pay_yearly_text7: "Høy kvalitet",
    pay_yearly_text8: "Ingen vannmerke",
    toast_wrong_load: "Feil ved innlasting av video. Prøv en annen fil.",
    pay_text3: "75 % RABATT",
    pay_yearly_text5: "Studiepoeng",
    faceswap_text13: "Ansiktsbytte",
    pay_text2: "Opp til",
    pay_yearly_text6: "Bytt på sekunder",
    pay_text1: "Få Premium",
    pay_yearly_text3: "uke",
    faceswap_text11: "JPG / PNG",
    pay_yearly_text4: "fakturert et år",
    faceswap_text12: "Bilder du kan prøve",
    target_video_text: "Mål video",
    ultra_video_length: "Opptil 1 min",
    pay_text4: "for det første abonnementet.",
    login_text2: "For å nyte ubegrenset Face Swap!",
    login_text1: "Logg på",
    no_more_credits:
      "Det er ikke flere kreditter, oppgrader planen din for å få mer.",
    target_video_duration_text: "Mindre enn 6s",
    test: "hei verden",
    videoswap_text7: "Bytt video",
    loading_text1: "30 sekunder til 5 minutter anslått",
    videoswap_text6: "Legg til målvideo",
    loading_text2:
      "Vennligst hold deg på den gjeldende siden, ellers vil du miste det beste resultatet.",
    videoswap_text9: "MP4 / MOV",
    loading_text3: "Få fart",
    web_request_error: "Nettverksfeil, vennligst sjekk og prøv igjen senere",
    toast_wrong_fromat: "Last opp en video i MP4- eller MOV-format.",
    toast_wrong_size: "Last opp en video som er mindre enn 50 MB.",
    mobile_text2: "STORT SALG",
    mobile_text1: "Start nå",
    add_video_first_text: "Legg til en målvideo først.",
    toast_wrong_duration: "Last opp en video som er kortere enn 1 minutt.",
    pay_ultra_video_swap_text6: "Ultra Video Swap",
    ultra_video_swap_text1: "Maks 50 MB, opptil 1 min",
    video_ultra_text1: "Ultra",
    tab_video_text: "Video",
    videoswap_text12: "Videoer du kan prøve",
    toast_1: "Vennligst velg kildebildet.",
    normal_video_swap_credits_text: "(2 studiepoeng/video)",
    toast_2: "Noe galt skjedde, sjekk nettverket ditt og prøv på nytt.",
    pay_weekly_text2: "Kreditt",
    pay_weekly_text3: "Bytt på sekunder",
    faceswap_value_text1: "250+ studiepoeng",
    pay_weekly_text1: "Ukentlig",
    pay_subscribe: "ABONNER",
    faceswap_value_text3: "Ingen vannmerke",
    pay_monthly_text1: "Månedlig",
    faceswap_value_text2: "Hopp over køen",
    faceswap_value_text5: "Høy kvalitet",
    faceswap_value_text4: "Ingen annonser",
    pay_monthly_text4: "Høy kvalitet",
    pay_monthly_text5: "Ingen vannmerke",
    faceswap_value_text6: "Ingen restriksjoner",
    pay_monthly_text2: "Studiepoeng",
    pay_monthly_text3: "Bytt på sekunder",
    faceswap_text5: "Ta ansiktet",
    faceswap_text4: "Legg til ansiktsbilde",
    pay_monthly_text6: "Naken og NSFW",
    faceswap_text7: "Bytt ansikt",
    faceswap_text6: "Legg til målbilde",
    faceswap_text9: "JPG / PNG",
    faceswap_text8: "Ansiktsbilde",
    normal_video_swap_text1: "Maks 10 MB, opptil 4 s",
    pay_weekly_text6: "Naken og NSFW",
    pay_weekly_text4: "Høy kvalitet",
    ultra_video_swap_credits_text: "(10 studiepoeng/sekund)",
    ultra_video_size: "Maks 50 MB",
    ultra_high_quality: "Høy kvalitet",
    pay_weekly_text5: "Ingen vannmerke",
    faceswap_text1: "Høy kvalitet",
    faceswap_value_button_text1: "Oppgrader",
    faceswap_text3: "Logg på",
    faceswap_text2: "Studiepoeng",
    pay_bottom_text1: "Sikkerhet Beskyttet av",
    result_text: "Resultat",
    pay_bottom_text2: "ChatUp AI",
    pay_bottom_text3: "Avbryt når som helst",
    tab_image_text: "Bilde",
  },
  hi: {
    pay_yearly_text1: "सालाना",
    login_text6: "डाउनलोड चालू करें",
    login_text5: "अधिक स्वैप करने के लिए",
    login_text4: "चैटअप एआई",
    message_info2: "कृपया चेहरा बदलने के लिए एक चेहरे की छवि जोड़ें।",
    login_text3: "डाउनलोड करना",
    message_info3: "कृपया सदस्यता लेने के लिए एक उत्पाद चुनें",
    credits_not_enough:
      "पर्याप्त क्रेडिट नहीं है, कृपया अधिक क्रेडिट खरीदें या अपनी योजना को अपग्रेड करें।",
    login_text9: "गूगल प्ले",
    message_info1: "कृपया पहले एक लक्ष्य छवि जोड़ें.",
    toast_timeout:
      "सर्वर ओवरलोड हो गया है और फेस स्वैप का समय समाप्त हो गया है। कृपया एक छोटा वीडियो अपलोड करने का प्रयास करें।",
    login_text8: "डाउनलोड चालू करें",
    login_text7: "ऐप स्टोर",
    select_img_toast_text: "कृपया एक चेहरे की छवि चुनें.",
    pay_yearly_text9: "नग्न और NSFW",
    faceswap_text10: "लक्ष्य छवि",
    pay_yearly_text7: "उच्च गुणवत्ता",
    pay_yearly_text8: "कोई वॉटरमार्क नहीं",
    toast_wrong_load:
      "वीडियो लोड करने में त्रुटि. कृपया कोई अन्य फ़ाइल आज़माएँ.",
    pay_text3: "75% की छूट",
    pay_yearly_text5: "क्रेडिट",
    faceswap_text13: "चेहरे बदलना",
    pay_text2: "तक",
    pay_yearly_text6: "सेकंड में स्वैप करें",
    pay_text1: "प्रीमियम प्राप्त करें",
    pay_yearly_text3: "सप्ताह",
    faceswap_text11: "जेपीजी/पीएनजी",
    pay_yearly_text4: "एक वर्ष का बिल दिया गया",
    faceswap_text12: "छवियाँ जिन्हें आप आज़मा सकते हैं",
    target_video_text: "लक्ष्य वीडियो",
    ultra_video_length: "1 मिनट तक",
    pay_text4: "पहली सदस्यता के लिए.",
    login_text2: "असीमित फेस स्वैप का आनंद लेने के लिए!",
    login_text1: "दाखिल करना",
    no_more_credits:
      "अब कोई क्रेडिट नहीं है, कृपया अधिक पाने के लिए अपनी योजना को अपग्रेड करें।",
    target_video_duration_text: "6s से कम",
    test: "हैलो वर्ल्ड",
    videoswap_text7: "वीडियो स्वैप करें",
    loading_text1: "30 सेकंड से 5 मिनट अनुमानित",
    videoswap_text6: "लक्ष्य वीडियो जोड़ें",
    loading_text2:
      "कृपया वर्तमान पृष्ठ पर बने रहें अन्यथा आप सर्वोत्तम परिणाम खो देंगे।",
    videoswap_text9: "एमपी4/एमओवी",
    loading_text3: "गति बढ़ाना",
    web_request_error:
      "नेटवर्क त्रुटि, कृपया जांचें और बाद में पुनः प्रयास करें",
    toast_wrong_fromat: "कृपया एक MP4 या MOV प्रारूप वीडियो अपलोड करें।",
    toast_wrong_size: "कृपया 50 एमबी से छोटा वीडियो अपलोड करें।",
    mobile_text2: "बड़ी बिक्री",
    mobile_text1: "शुरू करें",
    add_video_first_text: "कृपया पहले एक लक्ष्य वीडियो जोड़ें.",
    toast_wrong_duration: "कृपया 1 मिनट से छोटा वीडियो अपलोड करें।",
    pay_ultra_video_swap_text6: "अल्ट्रा वीडियो स्वैप",
    ultra_video_swap_text1: "अधिकतम 50 एमबी, 1 मिनट तक",
    video_ultra_text1: "अत्यंत",
    tab_video_text: "वीडियो",
    videoswap_text12: "वीडियो आप आज़मा सकते हैं",
    toast_1: "कृपया स्रोत छवि का चयन करें.",
    normal_video_swap_credits_text: "(2 क्रेडिट/वीडियो)",
    toast_2: "कुछ ग़लत हुआ, कृपया अपना नेटवर्क जाँचें और पुनः प्रयास करें।",
    pay_weekly_text2: "श्रेय",
    pay_weekly_text3: "सेकंड में स्वैप करें",
    faceswap_value_text1: "250+ क्रेडिट",
    pay_weekly_text1: "साप्ताहिक",
    pay_subscribe: "सदस्यता लें",
    faceswap_value_text3: "कोई वॉटरमार्क नहीं",
    pay_monthly_text1: "महीने के",
    faceswap_value_text2: "कतार छोड़ें",
    faceswap_value_text5: "उच्च गुणवत्ता",
    faceswap_value_text4: "कोई विज्ञापन नहीं",
    pay_monthly_text4: "उच्च गुणवत्ता",
    pay_monthly_text5: "कोई वॉटरमार्क नहीं",
    faceswap_value_text6: "कोई प्रतिबंध नहीं",
    pay_monthly_text2: "क्रेडिट",
    pay_monthly_text3: "सेकंड में स्वैप करें",
    faceswap_text5: "चेहरा ले लो",
    faceswap_text4: "चेहरे की छवि जोड़ें",
    pay_monthly_text6: "नग्न और NSFW",
    faceswap_text7: "चेहरा बदलें",
    faceswap_text6: "लक्ष्य छवि जोड़ें",
    faceswap_text9: "जेपीजी/पीएनजी",
    faceswap_text8: "चेहरे की छवि",
    normal_video_swap_text1: "अधिकतम 10एमबी, 4एस तक",
    pay_weekly_text6: "नग्न और NSFW",
    pay_weekly_text4: "उच्च गुणवत्ता",
    ultra_video_swap_credits_text: "(10 क्रेडिट/सेकंड)",
    ultra_video_size: "अधिकतम 50एमबी",
    ultra_high_quality: "उच्च गुणवत्ता",
    pay_weekly_text5: "कोई वॉटरमार्क नहीं",
    faceswap_text1: "उच्च गुणवत्ता",
    faceswap_value_button_text1: "उन्नत करना",
    faceswap_text3: "दाखिल करना",
    faceswap_text2: "क्रेडिट",
    pay_bottom_text1: "सुरक्षा द्वारा संरक्षित",
    result_text: "परिणाम",
    pay_bottom_text2: "चैटअप एआई",
    pay_bottom_text3: "किसी भी समय रद्द करें",
    tab_image_text: "छवि",
  },
  fi: {
    pay_yearly_text1: "Vuosittain",
    login_text6: "Lataa PÄÄLLE",
    login_text5: "vaihtaa lisää",
    login_text4: "ChatUp AI",
    message_info2: "Lisää kasvokuva vaihtaaksesi kasvot.",
    login_text3: "Lataa",
    message_info3: "Valitse tilattava tuote",
    credits_not_enough:
      "Ei tarpeeksi krediittejä, osta lisää krediittejä tai päivitä suunnitelmaasi.",
    login_text9: "Google Play",
    message_info1: "Lisää ensin kohdekuva.",
    toast_timeout:
      "Palvelin on ylikuormitettu ja kasvojen vaihto aikakatkaistiin. Yritä ladata lyhyempi video.",
    login_text8: "Lataa PÄÄLLE",
    login_text7: "App Storesta",
    select_img_toast_text: "Valitse kasvokuva.",
    pay_yearly_text9: "Alaston & NSFW",
    faceswap_text10: "Kohdekuva",
    pay_yearly_text7: "Korkea laatu",
    pay_yearly_text8: "Ei vesileimaa",
    toast_wrong_load: "Virhe ladattaessa videota. Yritä toista tiedostoa.",
    pay_text3: "75% ALENNUS",
    pay_yearly_text5: "Krediitit",
    faceswap_text13: "Kasvojen vaihto",
    pay_text2: "Jopa",
    pay_yearly_text6: "Vaihda sekunneissa",
    pay_text1: "Hanki Premium",
    pay_yearly_text3: "viikko",
    faceswap_text11: "JPG / PNG",
    pay_yearly_text4: "laskutetaan vuosi",
    faceswap_text12: "Kuvia, joita voit kokeilla",
    target_video_text: "Kohdevideo",
    ultra_video_length: "Jopa 1 min",
    pay_text4: "ensimmäiselle tilaukselle.",
    login_text2: "Nauti rajattomasta kasvojenvaihdosta!",
    login_text1: "Kirjaudu sisään",
    no_more_credits:
      "Krediittejä ei ole enää. Päivitä suunnitelmasi saadaksesi lisää.",
    target_video_duration_text: "Alle 6s",
    test: "hei maailma",
    videoswap_text7: "Vaihda video",
    loading_text1: "Arvioitu 30 sekunnista 5 minuuttiin",
    videoswap_text6: "Lisää kohdevideo",
    loading_text2: "Pysy nykyisellä sivulla tai menetät parhaan tuloksen.",
    videoswap_text9: "MP4 / MOV",
    loading_text3: "Nopeuttaa",
    web_request_error: "Verkkovirhe, tarkista ja yritä myöhemmin uudelleen",
    toast_wrong_fromat: "Lataa MP4- tai MOV-muotoinen video.",
    toast_wrong_size: "Lataa alle 50 Mt video.",
    mobile_text2: "SUURI MYYNTI",
    mobile_text1: "Aloita nyt",
    add_video_first_text: "Lisää ensin kohdevideo.",
    toast_wrong_duration: "Lataa video, joka on lyhyempi kuin 1 minuutti.",
    pay_ultra_video_swap_text6: "Ultra Video Swap",
    ultra_video_swap_text1: "Enintään 50 Mt, Jopa 1 min",
    video_ultra_text1: "Ultra",
    tab_video_text: "Video",
    videoswap_text12: "Videoita, joita voit kokeilla",
    toast_1: "Valitse lähdekuva.",
    normal_video_swap_credits_text: "(2 krediittiä / video)",
    toast_2: "Tapahtui jotain vikaa. Tarkista verkkosi ja yritä uudelleen.",
    pay_weekly_text2: "Luotto",
    pay_weekly_text3: "Vaihda sekunneissa",
    faceswap_value_text1: "250+ krediittiä",
    pay_weekly_text1: "Viikoittain",
    pay_subscribe: "TILAA",
    faceswap_value_text3: "Ei vesileimaa",
    pay_monthly_text1: "Kuukausittain",
    faceswap_value_text2: "Ohita jono",
    faceswap_value_text5: "Korkea laatu",
    faceswap_value_text4: "Ei mainoksia",
    pay_monthly_text4: "Korkea laatu",
    pay_monthly_text5: "Ei vesileimaa",
    faceswap_value_text6: "Ei rajoituksia",
    pay_monthly_text2: "Krediitit",
    pay_monthly_text3: "Vaihda sekunneissa",
    faceswap_text5: "Ota kasvot",
    faceswap_text4: "Lisää kasvokuva",
    pay_monthly_text6: "Alaston & NSFW",
    faceswap_text7: "Vaihda kasvot",
    faceswap_text6: "Lisää kohdekuva",
    faceswap_text9: "JPG / PNG",
    faceswap_text8: "Kasvokuva",
    normal_video_swap_text1: "Enintään 10 Mt, enintään 4 s",
    pay_weekly_text6: "Alaston & NSFW",
    pay_weekly_text4: "Korkea laatu",
    ultra_video_swap_credits_text: "(10 opintopistettä / sekunti)",
    ultra_video_size: "Enintään 50 MB",
    ultra_high_quality: "Korkea laatu",
    pay_weekly_text5: "Ei vesileimaa",
    faceswap_text1: "Korkea laatu",
    faceswap_value_button_text1: "Päivitä",
    faceswap_text3: "Kirjaudu sisään",
    faceswap_text2: "Krediitit",
    pay_bottom_text1: "Turvallisuus Suojattu",
    result_text: "Tulos",
    pay_bottom_text2: "ChatUp AI",
    pay_bottom_text3: "Peru milloin tahansa",
    tab_image_text: "Kuva",
  },
  ru: {
    pay_yearly_text1: "Ежегодно",
    login_text6: "Скачать ВКЛ.",
    login_text5: "чтобы поменять больше",
    login_text4: "Чат с искусственным интеллектом",
    message_info2:
      "Пожалуйста, добавьте изображение лица, чтобы поменять лицо.",
    login_text3: "Скачать",
    message_info3: "Пожалуйста, выберите продукт для подписки",
    credits_not_enough:
      "Недостаточно кредитов. Приобретите больше кредитов или обновите свой план.",
    login_text9: "Гугл Плей",
    message_info1: "Сначала добавьте целевое изображение.",
    toast_timeout:
      "Сервер перегружен, и истекло время замены лица. Попробуйте загрузить более короткое видео.",
    login_text8: "Скачать ВКЛ.",
    login_text7: "Магазин приложений",
    select_img_toast_text: "Пожалуйста, выберите изображение лица.",
    pay_yearly_text9: "Ню и NSFW",
    faceswap_text10: "Целевое изображение",
    pay_yearly_text7: "Высокое качество",
    pay_yearly_text8: "Нет водяного знака",
    toast_wrong_load:
      "Ошибка загрузки видео. Пожалуйста, попробуйте другой файл.",
    pay_text3: "СКИДКА 75%",
    pay_yearly_text5: "Кредиты",
    faceswap_text13: "Смена лица",
    pay_text2: "До",
    pay_yearly_text6: "Обмен за секунды",
    pay_text1: "Получить Премиум",
    pay_yearly_text3: "неделя",
    faceswap_text11: "JPG / PNG",
    pay_yearly_text4: "выставлен счет за год",
    faceswap_text12: "Изображения, которые вы можете попробовать",
    target_video_text: "Целевое видео",
    ultra_video_length: "До 1 минуты",
    pay_text4: "за первую подписку.",
    login_text2: "Чтобы насладиться неограниченной заменой лиц!",
    login_text1: "Войти",
    no_more_credits:
      "Кредитов больше нет. Обновите свой план, чтобы получить больше.",
    target_video_duration_text: "Менее 6 секунд",
    test: "Привет, мир",
    videoswap_text7: "Обмен видео",
    loading_text1: "Примерно от 30 секунд до 5 минут",
    videoswap_text6: "Добавить целевое видео",
    loading_text2:
      "Пожалуйста, оставайтесь на текущей странице, иначе вы потеряете лучший результат.",
    videoswap_text9: "MP4/МОВ",
    loading_text3: "Ускорение",
    web_request_error: "Ошибка сети. Проверьте и повторите попытку позже.",
    toast_wrong_fromat: "Пожалуйста, загрузите видео в формате MP4 или MOV.",
    toast_wrong_size: "Пожалуйста, загрузите видео размером менее 50 МБ.",
    mobile_text2: "БОЛЬШИЕ ПРОДАЖИ",
    mobile_text1: "Начать сейчас",
    add_video_first_text: "Сначала добавьте целевое видео.",
    toast_wrong_duration:
      "Пожалуйста, загрузите видео продолжительностью менее 1 минуты.",
    pay_ultra_video_swap_text6: "Ультра замена видео",
    ultra_video_swap_text1: "Макс. 50 МБ, до 1 минуты",
    video_ultra_text1: "Ультра",
    tab_video_text: "Видео",
    videoswap_text12: "Видео, которые вы можете попробовать",
    toast_1: "Пожалуйста, выберите исходное изображение.",
    normal_video_swap_credits_text: "(2 кредита/видео)",
    toast_2: "Произошла ошибка. Проверьте сеть и повторите попытку.",
    pay_weekly_text2: "Кредит",
    pay_weekly_text3: "Обмен за секунды",
    faceswap_value_text1: "250+ кредитов",
    pay_weekly_text1: "Еженедельно",
    pay_subscribe: "ПОДПИСАТЬСЯ",
    faceswap_value_text3: "Нет водяного знака",
    pay_monthly_text1: "Ежемесячно",
    faceswap_value_text2: "Пропустить очередь",
    faceswap_value_text5: "Высокое качество",
    faceswap_value_text4: "Нет рекламы",
    pay_monthly_text4: "Высокое качество",
    pay_monthly_text5: "Нет водяного знака",
    faceswap_value_text6: "Нет ограничений",
    pay_monthly_text2: "Кредиты",
    pay_monthly_text3: "Обмен за секунды",
    faceswap_text5: "Возьмите лицо",
    faceswap_text4: "Добавить изображение лица",
    pay_monthly_text6: "Ню и NSFW",
    faceswap_text7: "Поменять лицо",
    faceswap_text6: "Добавить целевое изображение",
    faceswap_text9: "JPG / PNG",
    faceswap_text8: "Изображение лица",
    normal_video_swap_text1: "Макс. 10 МБ, до 4 с.",
    pay_weekly_text6: "Ню и NSFW",
    pay_weekly_text4: "Высокое качество",
    ultra_video_swap_credits_text: "(10 кредитов в секунду)",
    ultra_video_size: "Макс. 50 МБ",
    ultra_high_quality: "Высокое качество",
    pay_weekly_text5: "Нет водяного знака",
    faceswap_text1: "Высокое качество",
    faceswap_value_button_text1: "Обновление",
    faceswap_text3: "Войти",
    faceswap_text2: "Кредиты",
    pay_bottom_text1: "Безопасность Защищена",
    result_text: "Результат",
    pay_bottom_text2: "Чат с искусственным интеллектом",
    pay_bottom_text3: "Отменить в любое время",
    tab_image_text: "Изображение",
  },
  pt: {
    pay_yearly_text1: "Anual",
    login_text6: "Baixar ATIVADO",
    login_text5: "para trocar mais",
    login_text4: "IA de bate-papo",
    message_info2: "Adicione uma imagem de rosto para trocar de rosto.",
    login_text3: "Download",
    message_info3: "Selecione um produto para assinar",
    credits_not_enough:
      "Não há créditos suficientes. Compre mais créditos ou atualize seu plano.",
    login_text9: "Google Play",
    message_info1: "Adicione uma imagem de destino primeiro.",
    toast_timeout:
      "O servidor está sobrecarregado e a troca de rosto expirou. Tente enviar um vídeo mais curto.",
    login_text8: "Baixar ATIVADO",
    login_text7: "Loja de aplicativos",
    select_img_toast_text: "Selecione uma imagem de rosto.",
    pay_yearly_text9: "Nu e NSFW",
    faceswap_text10: "Imagem alvo",
    pay_yearly_text7: "Alta qualidade",
    pay_yearly_text8: "Sem marca d'água",
    toast_wrong_load:
      "Erro ao carregar o vídeo. Por favor, tente outro arquivo.",
    pay_text3: "75% DE DESCONTO",
    pay_yearly_text5: "Créditos",
    faceswap_text13: "Troca de rosto",
    pay_text2: "Até",
    pay_yearly_text6: "Troque em segundos",
    pay_text1: "Seja Premium",
    pay_yearly_text3: "semana",
    faceswap_text11: "JPG/PNG",
    pay_yearly_text4: "faturado por ano",
    faceswap_text12: "Imagens que você pode experimentar",
    target_video_text: "Vídeo alvo",
    ultra_video_length: "Até 1 minuto",
    pay_text4: "para a primeira assinatura.",
    login_text2: "Para desfrutar de troca de rosto ilimitada!",
    login_text1: "Entrar",
    no_more_credits:
      "Não há mais créditos, atualize seu plano para obter mais.",
    target_video_duration_text: "Menos de 6s",
    test: "olá mundo",
    videoswap_text7: "Trocar vídeo",
    loading_text1: "30 segundos a 5 minutos estimados",
    videoswap_text6: "Adicionar vídeo alvo",
    loading_text2:
      "Por favor, permaneça na página atual ou perderá o melhor resultado.",
    videoswap_text9: "MP4/MOV",
    loading_text3: "Acelerar",
    web_request_error: "Erro de rede, verifique e tente novamente mais tarde",
    toast_wrong_fromat: "Envie um vídeo no formato MP4 ou MOV.",
    toast_wrong_size: "Envie um vídeo com menos de 50 MB.",
    mobile_text2: "GRANDES VENDAS",
    mobile_text1: "Comece agora",
    add_video_first_text: "Adicione um vídeo de destino primeiro.",
    toast_wrong_duration: "Envie um vídeo com menos de 1 minuto.",
    pay_ultra_video_swap_text6: "Troca de Ultra Vídeo",
    ultra_video_swap_text1: "Máximo de 50 MB, até 1 minuto",
    video_ultra_text1: "Ultra",
    tab_video_text: "Vídeo",
    videoswap_text12: "Vídeos que você pode experimentar",
    toast_1: "Selecione a imagem de origem.",
    normal_video_swap_credits_text: "(2 créditos/vídeo)",
    toast_2: "Algo de errado aconteceu. Verifique sua rede e tente novamente.",
    pay_weekly_text2: "Crédito",
    pay_weekly_text3: "Troque em segundos",
    faceswap_value_text1: "Mais de 250 créditos",
    pay_weekly_text1: "Semanalmente",
    pay_subscribe: "ASSINAR",
    faceswap_value_text3: "Sem marca d'água",
    pay_monthly_text1: "Mensal",
    faceswap_value_text2: "Pular fila",
    faceswap_value_text5: "Alta qualidade",
    faceswap_value_text4: "Sem anúncios",
    pay_monthly_text4: "Alta qualidade",
    pay_monthly_text5: "Sem marca d'água",
    faceswap_value_text6: "Sem restrições",
    pay_monthly_text2: "Créditos",
    pay_monthly_text3: "Troque em segundos",
    faceswap_text5: "Tome cara",
    faceswap_text4: "Adicionar imagem de rosto",
    pay_monthly_text6: "Nu e NSFW",
    faceswap_text7: "Trocar rosto",
    faceswap_text6: "Adicionar imagem alvo",
    faceswap_text9: "JPG/PNG",
    faceswap_text8: "Imagem facial",
    normal_video_swap_text1: "Máximo de 10 MB, até 4s",
    pay_weekly_text6: "Nu e NSFW",
    pay_weekly_text4: "Alta qualidade",
    ultra_video_swap_credits_text: "(10 créditos/segundo)",
    ultra_video_size: "Máximo de 50 MB",
    ultra_high_quality: "Alta qualidade",
    pay_weekly_text5: "Sem marca d'água",
    faceswap_text1: "Alta qualidade",
    faceswap_value_button_text1: "Atualizar",
    faceswap_text3: "Entrar",
    faceswap_text2: "Créditos",
    pay_bottom_text1: "Segurança protegida por",
    result_text: "Resultado",
    pay_bottom_text2: "IA de bate-papo",
    pay_bottom_text3: "Cancele a qualquer momento",
    tab_image_text: "Imagem",
  },
  zhtw: {
    pay_yearly_text1: "每年",
    login_text6: "下載開啟",
    login_text5: "交換更多",
    login_text4: "聊天人工智慧",
    message_info2: "請添加臉部圖像以交換臉部。",
    login_text3: "下載",
    message_info3: "請選擇要訂閱的產品",
    credits_not_enough: "積分不足，請購買更多積分或升級您的方案。",
    login_text9: "谷歌遊戲",
    message_info1: "請先新增目標影像。",
    toast_timeout: "伺服器超載，換臉超時。請嘗試上傳較短的影片。",
    login_text8: "下載開啟",
    login_text7: "應用程式商店",
    select_img_toast_text: "請選擇臉部影像。",
    pay_yearly_text9: "裸體與不適合上班族",
    faceswap_text10: "目標影像",
    pay_yearly_text7: "高品質",
    pay_yearly_text8: "無浮水印",
    toast_wrong_load: "載入影片時出錯。請嘗試另一個文件。",
    pay_text3: "75% 折扣",
    pay_yearly_text5: "製作人員",
    faceswap_text13: "換臉",
    pay_text2: "最多",
    pay_yearly_text6: "秒換",
    pay_text1: "獲得高級版",
    pay_yearly_text3: "星期",
    faceswap_text11: "JPG / PNG",
    pay_yearly_text4: "按年計費",
    faceswap_text12: "您可以嘗試的圖片",
    target_video_text: "目標影片",
    ultra_video_length: "長達 1 分鐘",
    pay_text4: "第一次訂閱。",
    login_text2: "享受無限換臉！",
    login_text1: "登入",
    no_more_credits: "沒有更多積分，請升級您的計劃以獲得更多積分。",
    target_video_duration_text: "小於6秒",
    test: "你好世界",
    videoswap_text7: "交換視頻",
    loading_text1: "預計 30 秒至 5 分鐘",
    videoswap_text6: "添加目標視頻",
    loading_text2: "請留在當前頁面，否則您將失去最佳結果。",
    videoswap_text9: "MP4/MOV",
    loading_text3: "加速",
    web_request_error: "網路錯誤，請檢查並稍後重試",
    toast_wrong_fromat: "請上傳 MP4 或 MOV 格式的影片。",
    toast_wrong_size: "請上傳小於 50MB 的影片。",
    mobile_text2: "大銷售",
    mobile_text1: "現在開始",
    add_video_first_text: "請先加入目標影片。",
    toast_wrong_duration: "請上傳短於1分鐘的影片。",
    pay_ultra_video_swap_text6: "超視訊交換",
    ultra_video_swap_text1: "最大 50MB，最長 1 分鐘",
    video_ultra_text1: "極端主義者",
    tab_video_text: "影片",
    videoswap_text12: "您可以嘗試的視頻",
    toast_1: "請選擇來源影像。",
    normal_video_swap_credits_text: "（2 學分/影片）",
    toast_2: "發生錯誤，請檢查您的網路並重試。",
    pay_weekly_text2: "信用",
    pay_weekly_text3: "秒換",
    faceswap_value_text1: "250+ 學分",
    pay_weekly_text1: "每週",
    pay_subscribe: "訂閱",
    faceswap_value_text3: "無浮水印",
    pay_monthly_text1: "每月",
    faceswap_value_text2: "跳過隊列",
    faceswap_value_text5: "高品質",
    faceswap_value_text4: "無廣告",
    pay_monthly_text4: "高品質",
    pay_monthly_text5: "無浮水印",
    faceswap_value_text6: "無限制",
    pay_monthly_text2: "製作人員",
    pay_monthly_text3: "秒換",
    faceswap_text5: "拿臉",
    faceswap_text4: "新增人臉圖像",
    pay_monthly_text6: "裸體與不適合上班族",
    faceswap_text7: "換臉",
    faceswap_text6: "新增目標影像",
    faceswap_text9: "JPG / PNG",
    faceswap_text8: "人臉影像",
    normal_video_swap_text1: "最大 10MB，最長 4 秒",
    pay_weekly_text6: "裸體與不適合上班族",
    pay_weekly_text4: "高品質",
    ultra_video_swap_credits_text: "（10 學分/秒）",
    ultra_video_size: "最大50MB",
    ultra_high_quality: "高品質",
    pay_weekly_text5: "無浮水印",
    faceswap_text1: "高品質",
    faceswap_value_button_text1: "升級",
    faceswap_text3: "登入",
    faceswap_text2: "製作人員",
    pay_bottom_text1: "安全保護",
    result_text: "結果",
    pay_bottom_text2: "聊天人工智慧",
    pay_bottom_text3: "隨時取消",
    tab_image_text: "影像",
  },
  hr: {
    pay_yearly_text1: "Godišnje",
    login_text6: "Preuzmi ON",
    login_text5: "za razmjenu više",
    login_text4: "ChatUp AI",
    message_info2: "Dodajte sliku lica da biste zamijenili lice.",
    login_text3: "preuzimanje",
    message_info3: "Odaberite proizvod za pretplatu",
    credits_not_enough:
      "Nemate dovoljno kredita, kupite još kredita ili nadogradite svoj plan.",
    login_text9: "Google Play",
    message_info1: "Najprije dodajte ciljnu sliku.",
    toast_timeout:
      "Poslužitelj je preopterećen i zamjena lica je istekla. Pokušajte prenijeti kraći videozapis.",
    login_text8: "Preuzmi ON",
    login_text7: "App Store",
    select_img_toast_text: "Odaberite sliku lica.",
    pay_yearly_text9: "Goli i NSFW",
    faceswap_text10: "Ciljna slika",
    pay_yearly_text7: "Visoka kvaliteta",
    pay_yearly_text8: "Bez vodenog žiga",
    toast_wrong_load:
      "Pogreška pri učitavanju videa. Pokušajte s drugom datotekom.",
    pay_text3: "75% POPUSTA",
    pay_yearly_text5: "Zasluge",
    faceswap_text13: "Zamjena lica",
    pay_text2: "Sve do",
    pay_yearly_text6: "Zamijenite u sekundi",
    pay_text1: "Nabavite Premium",
    pay_yearly_text3: "tjedan",
    faceswap_text11: "JPG / PNG",
    pay_yearly_text4: "naplaćeno godišnje",
    faceswap_text12: "Slike koje možete isprobati",
    target_video_text: "Ciljani video",
    ultra_video_length: "Do 1 min",
    pay_text4: "za prvu pretplatu.",
    login_text2: "Za uživanje u neograničenoj zamjeni lica!",
    login_text1: "Prijavite se",
    no_more_credits:
      "Nema više kredita, nadogradite svoj plan da biste dobili više.",
    target_video_duration_text: "Manje od 6s",
    test: "zdravo svijete",
    videoswap_text7: "Zamijeni video",
    loading_text1: "Procijenjeno 30 sekundi do 5 minuta",
    videoswap_text6: "Dodaj ciljni video",
    loading_text2:
      "Ostanite na trenutnoj stranici ili ćete izgubiti najbolji rezultat.",
    videoswap_text9: "MP4 / MOV",
    loading_text3: "Ubrzati",
    web_request_error:
      "Mrežna pogreška, provjerite i pokušajte ponovno kasnije",
    toast_wrong_fromat: "Prenesite videozapis u MP4 ili MOV formatu.",
    toast_wrong_size: "Učitajte videozapis manji od 50 MB.",
    mobile_text2: "VELIKE RASPRODAJE",
    mobile_text1: "Počni sada",
    add_video_first_text: "Najprije dodajte ciljani videozapis.",
    toast_wrong_duration: "Prenesite videozapis kraći od 1 minute.",
    pay_ultra_video_swap_text6: "Ultra Video Swap",
    ultra_video_swap_text1: "Maks. 50 MB, do 1 min",
    video_ultra_text1: "Ultra",
    tab_video_text: "Video",
    videoswap_text12: "Videozapisi koje možete isprobati",
    toast_1: "Odaberite izvornu sliku.",
    normal_video_swap_credits_text: "(2 kredita / video)",
    toast_2: "Nešto nije u redu, provjerite svoju mrežu i pokušajte ponovo.",
    pay_weekly_text2: "Kredit",
    pay_weekly_text3: "Zamijenite u sekundi",
    faceswap_value_text1: "250+ kredita",
    pay_weekly_text1: "Tjedni",
    pay_subscribe: "PRETPLATITE SE",
    faceswap_value_text3: "Bez vodenog žiga",
    pay_monthly_text1: "Mjesečno",
    faceswap_value_text2: "Preskoči red čekanja",
    faceswap_value_text5: "Visoka kvaliteta",
    faceswap_value_text4: "Nema reklama",
    pay_monthly_text4: "Visoka kvaliteta",
    pay_monthly_text5: "Bez vodenog žiga",
    faceswap_value_text6: "Nema ograničenja",
    pay_monthly_text2: "Zasluge",
    pay_monthly_text3: "Zamijenite u sekundi",
    faceswap_text5: "Uzmi lice",
    faceswap_text4: "Dodajte sliku lica",
    pay_monthly_text6: "Goli i NSFW",
    faceswap_text7: "Zamijeni lice",
    faceswap_text6: "Dodaj ciljnu sliku",
    faceswap_text9: "JPG / PNG",
    faceswap_text8: "Slika lica",
    normal_video_swap_text1: "Maks. 10 MB, do 4 s",
    pay_weekly_text6: "Goli i NSFW",
    pay_weekly_text4: "Visoka kvaliteta",
    ultra_video_swap_credits_text: "(10 kredita/sekundi)",
    ultra_video_size: "Maksimalno 50 MB",
    ultra_high_quality: "Visoka kvaliteta",
    pay_weekly_text5: "Bez vodenog žiga",
    faceswap_text1: "Visoka kvaliteta",
    faceswap_value_button_text1: "Nadogradnja",
    faceswap_text3: "Prijavite se",
    faceswap_text2: "Zasluge",
    pay_bottom_text1: "Sigurnost Zaštićeno od",
    result_text: "Proizlaziti",
    pay_bottom_text2: "ChatUp AI",
    pay_bottom_text3: "Otkaži bilo kada",
    tab_image_text: "Slika",
  },
  fr: {
    pay_yearly_text1: "Annuel",
    login_text6: "Télécharger ON",
    login_text5: "pour échanger davantage",
    login_text4: "ChatUp IA",
    message_info2:
      "Veuillez ajouter une image de visage pour échanger le visage.",
    login_text3: "Télécharger",
    message_info3: "Veuillez sélectionner un produit pour vous abonner",
    credits_not_enough:
      "Pas assez de crédits, veuillez acheter plus de crédits ou mettre à niveau votre forfait.",
    login_text9: "Google Play",
    message_info1: "Veuillez d'abord ajouter une image cible.",
    toast_timeout:
      "Le serveur est surchargé et le délai d'échange de visage a expiré. Veuillez essayer de télécharger une vidéo plus courte.",
    login_text8: "Télécharger ON",
    login_text7: "Magasin d'applications",
    select_img_toast_text: "Veuillez sélectionner une image de visage.",
    pay_yearly_text9: "Nu et NSFW",
    faceswap_text10: "Image cible",
    pay_yearly_text7: "Haute qualité",
    pay_yearly_text8: "Pas de filigrane",
    toast_wrong_load:
      "Erreur de chargement de la vidéo. Veuillez essayer un autre fichier.",
    pay_text3: "75% DE RÉDUCTION",
    pay_yearly_text5: "Crédits",
    faceswap_text13: "Échange de visage",
    pay_text2: "Jusqu'à",
    pay_yearly_text6: "Échangez en quelques secondes",
    pay_text1: "Obtenez une prime",
    pay_yearly_text3: "semaine",
    faceswap_text11: "JPG/PNG",
    pay_yearly_text4: "facturé par an",
    faceswap_text12: "Images que vous pouvez essayer",
    target_video_text: "Vidéo cible",
    ultra_video_length: "Jusqu'à 1 minute",
    pay_text4: "pour le premier abonnement.",
    login_text2: "Pour profiter de Face Swap illimité !",
    login_text1: "Se connecter",
    no_more_credits:
      "Il n'y a plus de crédits, veuillez mettre à niveau votre forfait pour en obtenir plus.",
    target_video_duration_text: "Moins de 6s",
    test: "Bonjour le monde",
    videoswap_text7: "Échanger la vidéo",
    loading_text1: "30 secondes à 5 minutes estimées",
    videoswap_text6: "Ajouter une vidéo cible",
    loading_text2:
      "Veuillez rester sur la page actuelle ou vous perdrez le meilleur résultat.",
    videoswap_text9: "MP4 / MOV",
    loading_text3: "Accélérer",
    web_request_error:
      "Erreur réseau, veuillez vérifier et réessayer plus tard",
    toast_wrong_fromat: "Veuillez télécharger une vidéo au format MP4 ou MOV.",
    toast_wrong_size: "Veuillez télécharger une vidéo inférieure à 50 Mo.",
    mobile_text2: "GRANDES VENTES",
    mobile_text1: "Commencez maintenant",
    add_video_first_text: "Veuillez d'abord ajouter une vidéo cible.",
    toast_wrong_duration:
      "Veuillez télécharger une vidéo de moins d'une minute.",
    pay_ultra_video_swap_text6: "Échange ultra-vidéo",
    ultra_video_swap_text1: "Max 50 Mo, jusqu'à 1 minute",
    video_ultra_text1: "Ultra",
    tab_video_text: "Vidéo",
    videoswap_text12: "Vidéos que vous pouvez essayer",
    toast_1: "Veuillez sélectionner l'image source.",
    normal_video_swap_credits_text: "(2 crédits /vidéo)",
    toast_2:
      "Un problème s'est produit, veuillez vérifier votre réseau et réessayer.",
    pay_weekly_text2: "Crédit",
    pay_weekly_text3: "Échangez en quelques secondes",
    faceswap_value_text1: "250+ crédits",
    pay_weekly_text1: "Hebdomadaire",
    pay_subscribe: "S'ABONNER",
    faceswap_value_text3: "Pas de filigrane",
    pay_monthly_text1: "Mensuel",
    faceswap_value_text2: "Passer la file d'attente",
    faceswap_value_text5: "Haute qualité",
    faceswap_value_text4: "Aucune publicité",
    pay_monthly_text4: "Haute qualité",
    pay_monthly_text5: "Pas de filigrane",
    faceswap_value_text6: "Aucune restriction",
    pay_monthly_text2: "Crédits",
    pay_monthly_text3: "Échangez en quelques secondes",
    faceswap_text5: "Prendre le visage",
    faceswap_text4: "Ajouter une image de visage",
    pay_monthly_text6: "Nu et NSFW",
    faceswap_text7: "Changer de visage",
    faceswap_text6: "Ajouter une image cible",
    faceswap_text9: "JPG/PNG",
    faceswap_text8: "Image du visage",
    normal_video_swap_text1: "Max 10 Mo, jusqu'à 4 s",
    pay_weekly_text6: "Nu et NSFW",
    pay_weekly_text4: "Haute qualité",
    ultra_video_swap_credits_text: "(10 crédits /seconde)",
    ultra_video_size: "50 Mo maximum",
    ultra_high_quality: "Haute qualité",
    pay_weekly_text5: "Pas de filigrane",
    faceswap_text1: "Haute qualité",
    faceswap_value_button_text1: "Mise à niveau",
    faceswap_text3: "Se connecter",
    faceswap_text2: "Crédits",
    pay_bottom_text1: "Sécurité Protégée par",
    result_text: "Résultat",
    pay_bottom_text2: "ChatUp IA",
    pay_bottom_text3: "Annuler à tout moment",
    tab_image_text: "Image",
  },
  hu: {
    pay_yearly_text1: "Évi",
    login_text6: "Letöltés ON",
    login_text5: "hogy többet cserélj",
    login_text4: "ChatUp AI",
    message_info2: "Kérjük, adjon hozzá egy arcképet az arc cseréjéhez.",
    login_text3: "Letöltés",
    message_info3: "Kérjük, válasszon egy terméket az előfizetéshez",
    credits_not_enough:
      "Nincs elég kredit, vásároljon több kreditet, vagy frissítse tervét.",
    login_text9: "Google Play",
    message_info1: "Kérjük, először adjon hozzá egy célképet.",
    toast_timeout:
      "A szerver túlterhelt, és az arccsere lejárt. Kérjük, próbáljon meg egy rövidebb videót feltölteni.",
    login_text8: "Letöltés ON",
    login_text7: "App Store",
    select_img_toast_text: "Kérjük, válasszon egy arcképet.",
    pay_yearly_text9: "Meztelen és NSFW",
    faceswap_text10: "Célkép",
    pay_yearly_text7: "Kiváló minőség",
    pay_yearly_text8: "Nincs vízjel",
    toast_wrong_load:
      "Hiba a videó betöltésekor. Kérjük, próbálkozzon másik fájllal.",
    pay_text3: "75% KEDVEZMÉNY",
    pay_yearly_text5: "Kredit",
    faceswap_text13: "Arccsere",
    pay_text2: "Akár",
    pay_yearly_text6: "Csere másodpercek alatt",
    pay_text1: "Szerezzen prémiumot",
    pay_yearly_text3: "hét",
    faceswap_text11: "JPG / PNG",
    pay_yearly_text4: "évre számlázva",
    faceswap_text12: "Képek, amelyeket kipróbálhat",
    target_video_text: "Cél videó",
    ultra_video_length: "Akár 1 perc",
    pay_text4: "az első előfizetéshez.",
    login_text2: "Élvezd a korlátlan arccserét!",
    login_text1: "Jelentkezzen be",
    no_more_credits:
      "Nincs több kredit, frissítse tervét, hogy több kreditet kapjon.",
    target_video_duration_text: "Kevesebb, mint 6 másodperc",
    test: "helló világ",
    videoswap_text7: "Videó csere",
    loading_text1: "30 másodperctől 5 percig tart",
    videoswap_text6: "Célvideó hozzáadása",
    loading_text2:
      "Kérjük, maradjon az aktuális oldalon, különben elveszíti a legjobb eredményt.",
    videoswap_text9: "MP4 / MOV",
    loading_text3: "Felgyorsul",
    web_request_error: "Hálózati hiba, ellenőrizze, és próbálja újra később",
    toast_wrong_fromat: "Kérjük, töltsön fel MP4 vagy MOV formátumú videót.",
    toast_wrong_size: "Kérjük, töltsön fel 50 MB-nál kisebb videót.",
    mobile_text2: "NAGY ELADÁSOK",
    mobile_text1: "Kezdje most",
    add_video_first_text: "Kérjük, először adjon hozzá egy célvideót.",
    toast_wrong_duration: "Kérjük, töltsön fel 1 percnél rövidebb videót.",
    pay_ultra_video_swap_text6: "Ultra Video Swap",
    ultra_video_swap_text1: "Max 50 MB, akár 1 perc",
    video_ultra_text1: "Ultra",
    tab_video_text: "Videó",
    videoswap_text12: "Videók, amelyeket kipróbálhat",
    toast_1: "Kérjük, válassza ki a forrásképet.",
    normal_video_swap_credits_text: "(2 kredit/videó)",
    toast_2:
      "Valami hiba történt. Kérjük, ellenőrizze a hálózatot, és próbálja újra.",
    pay_weekly_text2: "Hitel",
    pay_weekly_text3: "Csere másodpercek alatt",
    faceswap_value_text1: "250+ kredit",
    pay_weekly_text1: "Heti",
    pay_subscribe: "ELŐFIZETÉS",
    faceswap_value_text3: "Nincs vízjel",
    pay_monthly_text1: "Havi",
    faceswap_value_text2: "Sor kihagyása",
    faceswap_value_text5: "Kiváló minőség",
    faceswap_value_text4: "Nincsenek hirdetések",
    pay_monthly_text4: "Kiváló minőség",
    pay_monthly_text5: "Nincs vízjel",
    faceswap_value_text6: "Nincs korlátozás",
    pay_monthly_text2: "Kredit",
    pay_monthly_text3: "Csere másodpercek alatt",
    faceswap_text5: "Vedd szembe magad",
    faceswap_text4: "Arckép hozzáadása",
    pay_monthly_text6: "Meztelen és NSFW",
    faceswap_text7: "Cserélje ki az arcot",
    faceswap_text6: "Célkép hozzáadása",
    faceswap_text9: "JPG / PNG",
    faceswap_text8: "Arckép",
    normal_video_swap_text1: "Max 10 MB, legfeljebb 4 s",
    pay_weekly_text6: "Meztelen és NSFW",
    pay_weekly_text4: "Kiváló minőség",
    ultra_video_swap_credits_text: "(10 kredit/másodperc)",
    ultra_video_size: "Max 50 MB",
    ultra_high_quality: "Kiváló minőség",
    pay_weekly_text5: "Nincs vízjel",
    faceswap_text1: "Kiváló minőség",
    faceswap_value_button_text1: "Frissítés",
    faceswap_text3: "Jelentkezzen be",
    faceswap_text2: "Kredit",
    pay_bottom_text1: "Biztonság Védett:",
    result_text: "Eredmény",
    pay_bottom_text2: "ChatUp AI",
    pay_bottom_text3: "Bármikor lemondhatja",
    tab_image_text: "Kép",
  },
  uk: {
    pay_yearly_text1: "Щорічно",
    login_text6: "Завантажити ON",
    login_text5: "щоб поміняти більше",
    login_text4: "ChatUp AI",
    message_info2: "Щоб поміняти обличчя, додайте зображення обличчя.",
    login_text3: "Завантажити",
    message_info3: "Будь ласка, виберіть продукт для підписки",
    credits_not_enough:
      "Недостатньо кредитів, будь ласка, придбайте більше кредитів або оновіть свій план.",
    login_text9: "Google Play",
    message_info1: "Спочатку додайте цільове зображення.",
    toast_timeout:
      "Сервер перевантажено, а час очікування заміни обличчя минув. Будь ласка, спробуйте завантажити коротше відео.",
    login_text8: "Завантажити ON",
    login_text7: "App Store",
    select_img_toast_text: "Виберіть зображення обличчя.",
    pay_yearly_text9: "Оголена та NSFW",
    faceswap_text10: "Цільове зображення",
    pay_yearly_text7: "Висока якість",
    pay_yearly_text8: "Без водяного знака",
    toast_wrong_load: "Помилка завантаження відео. Спробуйте інший файл.",
    pay_text3: "ЗНИЖКА 75%.",
    pay_yearly_text5: "Кредити",
    faceswap_text13: "Обмін особами",
    pay_text2: "До",
    pay_yearly_text6: "Обмін за секунди",
    pay_text1: "Отримайте Premium",
    pay_yearly_text3: "тиждень",
    faceswap_text11: "JPG / PNG",
    pay_yearly_text4: "виставлений рахунок на рік",
    faceswap_text12: "Зображення, які ви можете спробувати",
    target_video_text: "Цільове відео",
    ultra_video_length: "До 1 хв",
    pay_text4: "за першу підписку.",
    login_text2: "Щоб насолоджуватися необмеженою зміною обличчя!",
    login_text1: "Увійти",
    no_more_credits:
      "Більше немає кредитів, оновіть свій план, щоб отримати більше.",
    target_video_duration_text: "Менше 6 с",
    test: "привіт світ",
    videoswap_text7: "Поміняти відео",
    loading_text1: "Приблизно від 30 секунд до 5 хвилин",
    videoswap_text6: "Додати цільове відео",
    loading_text2:
      "Залишайтеся на поточній сторінці, інакше ви втратите найкращий результат.",
    videoswap_text9: "MP4 / MOV",
    loading_text3: "Прискорення",
    web_request_error: "Помилка мережі, перевірте та повторіть спробу пізніше",
    toast_wrong_fromat: "Завантажте відео у форматі MP4 або MOV.",
    toast_wrong_size: "Будь ласка, завантажте відео розміром менше 50 Мб.",
    mobile_text2: "ВЕЛИКІ РОЗПРОДАЖІ",
    mobile_text1: "Почніть зараз",
    add_video_first_text: "Спочатку додайте цільове відео.",
    toast_wrong_duration:
      "Будь ласка, завантажте відео тривалістю менше 1 хвилини.",
    pay_ultra_video_swap_text6: "Ultra Video Swap",
    ultra_video_swap_text1: "Макс. 50 МБ, до 1 хв",
    video_ultra_text1: "Ультра",
    tab_video_text: "відео",
    videoswap_text12: "Відео, які ви можете спробувати",
    toast_1: "Виберіть вихідне зображення.",
    normal_video_swap_credits_text: "(2 кредити/відео)",
    toast_2: "Сталася якась помилка. Перевірте мережу та повторіть спробу.",
    pay_weekly_text2: "Кредит",
    pay_weekly_text3: "Обмін за секунди",
    faceswap_value_text1: "250+ кредитів",
    pay_weekly_text1: "Щотижня",
    pay_subscribe: "ПІДПИСАТИСЯ",
    faceswap_value_text3: "Без водяного знака",
    pay_monthly_text1: "Щомісяця",
    faceswap_value_text2: "Пропустити чергу",
    faceswap_value_text5: "Висока якість",
    faceswap_value_text4: "Без реклами",
    pay_monthly_text4: "Висока якість",
    pay_monthly_text5: "Без водяного знака",
    faceswap_value_text6: "Жодних обмежень",
    pay_monthly_text2: "Кредити",
    pay_monthly_text3: "Обмін за секунди",
    faceswap_text5: "Прийняти обличчя",
    faceswap_text4: "Додайте зображення обличчя",
    pay_monthly_text6: "Оголена та NSFW",
    faceswap_text7: "Поміняти обличчя",
    faceswap_text6: "Додати цільове зображення",
    faceswap_text9: "JPG / PNG",
    faceswap_text8: "Зображення обличчя",
    normal_video_swap_text1: "Макс. 10 МБ, до 4 с",
    pay_weekly_text6: "Оголена та NSFW",
    pay_weekly_text4: "Висока якість",
    ultra_video_swap_credits_text: "(10 кредитів/секунду)",
    ultra_video_size: "Макс. 50 Мб",
    ultra_high_quality: "Висока якість",
    pay_weekly_text5: "Без водяного знака",
    faceswap_text1: "Висока якість",
    faceswap_value_button_text1: "Оновлення",
    faceswap_text3: "Увійти",
    faceswap_text2: "Кредити",
    pay_bottom_text1: "Безпека Захищено",
    result_text: "Результат",
    pay_bottom_text2: "ChatUp AI",
    pay_bottom_text3: "Скасувати будь-коли",
    tab_image_text: "Зображення",
  },
  sk: {
    pay_yearly_text1: "Ročne",
    login_text6: "Stiahnuť ON",
    login_text5: "na výmenu viac",
    login_text4: "ChatUp AI",
    message_info2: "Ak chcete vymeniť tvár, pridajte obrázok tváre.",
    login_text3: "Stiahnuť",
    message_info3: "Vyberte si produkt na odber",
    credits_not_enough:
      "Nemáte dostatok kreditov, zakúpte si ďalšie kredity alebo inovujte svoj plán.",
    login_text9: "Google Play",
    message_info1: "Najprv pridajte cieľový obrázok.",
    toast_timeout:
      "Server je preťažený a časový limit výmeny tváre vypršal. Skúste nahrať kratšie video.",
    login_text8: "Stiahnuť ON",
    login_text7: "App Store",
    select_img_toast_text: "Vyberte obrázok tváre.",
    pay_yearly_text9: "Nude & NSFW",
    faceswap_text10: "Cieľový obrázok",
    pay_yearly_text7: "Vysoká kvalita",
    pay_yearly_text8: "Bez vodoznaku",
    toast_wrong_load: "Chyba pri načítavaní videa. Skúste iný súbor.",
    pay_text3: "ZĽAVA 75 %.",
    pay_yearly_text5: "Kredity",
    faceswap_text13: "Výmena tváre",
    pay_text2: "Až do",
    pay_yearly_text6: "Výmena za pár sekúnd",
    pay_text1: "Získajte Premium",
    pay_yearly_text3: "týždeň",
    faceswap_text11: "JPG / PNG",
    pay_yearly_text4: "účtovaný rok",
    faceswap_text12: "Obrázky, ktoré môžete vyskúšať",
    target_video_text: "Cieľové video",
    ultra_video_length: "Až 1 min",
    pay_text4: "za prvé predplatné.",
    login_text2: "Užite si neobmedzenú výmenu tváre!",
    login_text1: "Prihlásiť sa",
    no_more_credits:
      "Už nie sú žiadne kredity, inovujte svoj plán, aby ste získali viac.",
    target_video_duration_text: "Menej ako 6 s",
    test: "ahoj svet",
    videoswap_text7: "Vymeňte video",
    loading_text1: "Odhaduje sa 30 sekúnd až 5 minút",
    videoswap_text6: "Pridať cieľové video",
    loading_text2:
      "Zostaňte na aktuálnej stránke, inak stratíte najlepší výsledok.",
    videoswap_text9: "MP4 / MOV",
    loading_text3: "Zrýchliť",
    web_request_error: "Chyba siete, skontrolujte a skúste to znova neskôr",
    toast_wrong_fromat: "Nahrajte video vo formáte MP4 alebo MOV.",
    toast_wrong_size: "Nahrajte video menšie ako 50 MB.",
    mobile_text2: "VEĽKÝ VÝPREDAJ",
    mobile_text1: "Začnite hneď",
    add_video_first_text: "Najprv pridajte cieľové video.",
    toast_wrong_duration: "Nahrajte video kratšie ako 1 minútu.",
    pay_ultra_video_swap_text6: "Ultra Video Swap",
    ultra_video_swap_text1: "Max 50 MB, do 1 minúty",
    video_ultra_text1: "Ultra",
    tab_video_text: "Video",
    videoswap_text12: "Videá, ktoré môžete vyskúšať",
    toast_1: "Vyberte zdrojový obrázok.",
    normal_video_swap_credits_text: "(2 kredity / video)",
    toast_2: "Vyskytla sa chyba, skontrolujte svoju sieť a skúste to znova.",
    pay_weekly_text2: "Kredit",
    pay_weekly_text3: "Výmena za pár sekúnd",
    faceswap_value_text1: "250+ kreditov",
    pay_weekly_text1: "Týždenne",
    pay_subscribe: "PRIHLÁSIŤ SA",
    faceswap_value_text3: "Bez vodoznaku",
    pay_monthly_text1: "Mesačne",
    faceswap_value_text2: "Preskočiť poradie",
    faceswap_value_text5: "Vysoká kvalita",
    faceswap_value_text4: "Žiadne reklamy",
    pay_monthly_text4: "Vysoká kvalita",
    pay_monthly_text5: "Bez vodoznaku",
    faceswap_value_text6: "Žiadne obmedzenia",
    pay_monthly_text2: "Kredity",
    pay_monthly_text3: "Výmena za pár sekúnd",
    faceswap_text5: "Vezmite tvár",
    faceswap_text4: "Pridať obrázok tváre",
    pay_monthly_text6: "Nude & NSFW",
    faceswap_text7: "Vymeňte tvár",
    faceswap_text6: "Pridať cieľový obrázok",
    faceswap_text9: "JPG / PNG",
    faceswap_text8: "Obrázok tváre",
    normal_video_swap_text1: "Max 10 MB, až 4 s",
    pay_weekly_text6: "Nude & NSFW",
    pay_weekly_text4: "Vysoká kvalita",
    ultra_video_swap_credits_text: "(10 kreditov za sekundu)",
    ultra_video_size: "Maximálne 50 MB",
    ultra_high_quality: "Vysoká kvalita",
    pay_weekly_text5: "Bez vodoznaku",
    faceswap_text1: "Vysoká kvalita",
    faceswap_value_button_text1: "Inovovať",
    faceswap_text3: "Prihlásiť sa",
    faceswap_text2: "Kredity",
    pay_bottom_text1: "Zabezpečenie Chránené",
    result_text: "Výsledok",
    pay_bottom_text2: "ChatUp AI",
    pay_bottom_text3: "Zrušiť kedykoľvek",
    tab_image_text: "Obrázok",
  },
  sl: {
    pay_yearly_text1: "Letno",
    login_text6: "Prenos ON",
    login_text5: "za zamenjavo več",
    login_text4: "ChatUp AI",
    message_info2: "Če želite zamenjati obraz, dodajte sliko obraza.",
    login_text3: "Prenos",
    message_info3: "Izberite izdelek, na katerega se želite naročiti",
    credits_not_enough:
      "Ni dovolj kreditov, kupite več kreditov ali nadgradite svoj načrt.",
    login_text9: "Google Play",
    message_info1: "Najprej dodajte ciljno sliko.",
    toast_timeout:
      "Strežnik je preobremenjen in zamenjava obraza je potekla. Poskusite naložiti krajši video.",
    login_text8: "Prenos ON",
    login_text7: "App Store",
    select_img_toast_text: "Izberite sliko obraza.",
    pay_yearly_text9: "Gola in NSFW",
    faceswap_text10: "Ciljna slika",
    pay_yearly_text7: "Visoka kakovost",
    pay_yearly_text8: "Brez vodnega žiga",
    toast_wrong_load: "Napaka pri nalaganju videa. Poskusite z drugo datoteko.",
    pay_text3: "75% CENEJE",
    pay_yearly_text5: "Krediti",
    faceswap_text13: "Zamenjava obraza",
    pay_text2: "Do",
    pay_yearly_text6: "Zamenjaj v nekaj sekundah",
    pay_text1: "Pridobite Premium",
    pay_yearly_text3: "teden",
    faceswap_text11: "JPG / PNG",
    pay_yearly_text4: "zaračunana na leto",
    faceswap_text12: "Slike, ki jih lahko poskusite",
    target_video_text: "Ciljni video",
    ultra_video_length: "Do 1 min",
    pay_text4: "za prvo naročnino.",
    login_text2: "Za uživanje v neomejeni Face Swap!",
    login_text1: "Prijavite se",
    no_more_credits:
      "Ni več kreditov, nadgradite svoj načrt, da jih dobite več.",
    target_video_duration_text: "Manj kot 6s",
    test: "zdravo svet",
    videoswap_text7: "Zamenjaj video",
    loading_text1: "Ocenjeno od 30 sekund do 5 minut",
    videoswap_text6: "Dodajte ciljni video",
    loading_text2:
      "Ostanite na trenutni strani ali pa boste izgubili najboljši rezultat.",
    videoswap_text9: "MP4/MOV",
    loading_text3: "Pospeši",
    web_request_error: "Omrežna napaka, preverite in poskusite znova pozneje",
    toast_wrong_fromat: "Naložite video v formatu MP4 ali MOV.",
    toast_wrong_size: "Naložite video, manjši od 50 MB.",
    mobile_text2: "VELIKA RAZPRODAJA",
    mobile_text1: "Začni zdaj",
    add_video_first_text: "Najprej dodajte ciljni video.",
    toast_wrong_duration: "Naložite video, krajši od 1 minute.",
    pay_ultra_video_swap_text6: "Ultra Video Swap",
    ultra_video_swap_text1: "Največ 50 MB, do 1 min",
    video_ultra_text1: "Ultra",
    tab_video_text: "Video",
    videoswap_text12: "Videoposnetki, ki jih lahko poskusite",
    toast_1: "Izberite izvorno sliko.",
    normal_video_swap_credits_text: "(2 kredita/video)",
    toast_2:
      "Zgodilo se je nekaj narobe, preverite omrežje in poskusite znova.",
    pay_weekly_text2: "kredit",
    pay_weekly_text3: "Zamenjaj v nekaj sekundah",
    faceswap_value_text1: "250+ kreditov",
    pay_weekly_text1: "Tedensko",
    pay_subscribe: "NAROČI SE",
    faceswap_value_text3: "Brez vodnega žiga",
    pay_monthly_text1: "Mesečno",
    faceswap_value_text2: "Preskoči čakalno vrsto",
    faceswap_value_text5: "Visoka kakovost",
    faceswap_value_text4: "Brez oglasov",
    pay_monthly_text4: "Visoka kakovost",
    pay_monthly_text5: "Brez vodnega žiga",
    faceswap_value_text6: "Brez omejitev",
    pay_monthly_text2: "Krediti",
    pay_monthly_text3: "Zamenjaj v nekaj sekundah",
    faceswap_text5: "Vzemi obraz",
    faceswap_text4: "Dodajte sliko obraza",
    pay_monthly_text6: "Gola in NSFW",
    faceswap_text7: "Zamenjaj obraz",
    faceswap_text6: "Dodajte ciljno sliko",
    faceswap_text9: "JPG / PNG",
    faceswap_text8: "Slika obraza",
    normal_video_swap_text1: "Največ 10 MB, do 4 s",
    pay_weekly_text6: "Gola in NSFW",
    pay_weekly_text4: "Visoka kakovost",
    ultra_video_swap_credits_text: "(10 kreditov/sekundo)",
    ultra_video_size: "Največ 50 MB",
    ultra_high_quality: "Visoka kakovost",
    pay_weekly_text5: "Brez vodnega žiga",
    faceswap_text1: "Visoka kakovost",
    faceswap_value_button_text1: "Nadgradnja",
    faceswap_text3: "Prijavite se",
    faceswap_text2: "Krediti",
    pay_bottom_text1: "Varnost Varuje",
    result_text: "Rezultat",
    pay_bottom_text2: "ChatUp AI",
    pay_bottom_text3: "Prekliči kadar koli",
    tab_image_text: "Slika",
  },
  id: {
    pay_yearly_text1: "Tahunan",
    login_text6: "Unduh AKTIF",
    login_text5: "untuk Bertukar Lebih Banyak",
    login_text4: "Obrolan AI",
    message_info2: "Silakan tambahkan gambar wajah untuk menukar wajah.",
    login_text3: "Unduh",
    message_info3: "Silakan pilih produk untuk berlangganan",
    credits_not_enough:
      "Kredit tidak cukup, silakan beli kredit lebih banyak atau tingkatkan paket Anda.",
    login_text9: "Google Bermain",
    message_info1: "Silakan tambahkan gambar target terlebih dahulu.",
    toast_timeout:
      "Server kelebihan beban dan waktu pertukaran wajah habis. Silakan coba unggah video yang lebih pendek.",
    login_text8: "Unduh AKTIF",
    login_text7: "Toko Aplikasi",
    select_img_toast_text: "Silakan pilih gambar wajah.",
    pay_yearly_text9: "Telanjang & NSFW",
    faceswap_text10: "Gambar Sasaran",
    pay_yearly_text7: "Kualitas Tinggi",
    pay_yearly_text8: "Tanpa Tanda Air",
    toast_wrong_load:
      "Terjadi kesalahan saat memuat video. Silakan coba file lain.",
    pay_text3: "DISKON 75%.",
    pay_yearly_text5: "Kredit",
    faceswap_text13: "Tukar Wajah",
    pay_text2: "Hingga",
    pay_yearly_text6: "Tukar dalam hitungan detik",
    pay_text1: "Dapatkan Premium",
    pay_yearly_text3: "pekan",
    faceswap_text11: "JPG/PNG",
    pay_yearly_text4: "ditagih setahun",
    faceswap_text12: "Gambar yang bisa Anda coba",
    target_video_text: "Video Sasaran",
    ultra_video_length: "Hingga 1 menit",
    pay_text4: "untuk langganan pertama.",
    login_text2: "Untuk menikmati Tukar Wajah tanpa batas!",
    login_text1: "Masuk",
    no_more_credits:
      "Tidak ada kredit lagi, harap tingkatkan paket Anda untuk mendapatkan lebih banyak.",
    target_video_duration_text: "Kurang dari 6 detik",
    test: "halo dunia",
    videoswap_text7: "Tukar Video",
    loading_text1: "Perkiraan 30 detik hingga 5 menit",
    videoswap_text6: "Tambahkan Video Sasaran",
    loading_text2:
      "Harap tetap di halaman ini atau Anda akan kehilangan hasil terbaik.",
    videoswap_text9: "MP4/MOV",
    loading_text3: "Mempercepat",
    web_request_error: "Kesalahan jaringan, harap periksa dan coba lagi nanti",
    toast_wrong_fromat: "Silakan unggah video berformat MP4 atau MOV.",
    toast_wrong_size: "Silakan unggah video yang lebih kecil dari 50 MB.",
    mobile_text2: "PENJUALAN BESAR",
    mobile_text1: "Mulai Sekarang",
    add_video_first_text: "Silakan tambahkan video target terlebih dahulu.",
    toast_wrong_duration:
      "Silakan unggah video yang berdurasi kurang dari 1 menit.",
    pay_ultra_video_swap_text6: "Pertukaran Video Ultra",
    ultra_video_swap_text1: "Maks 50MB, Hingga 1 menit",
    video_ultra_text1: "Sangat",
    tab_video_text: "Video",
    videoswap_text12: "Video yang dapat Anda coba",
    toast_1: "Silakan pilih gambar sumber.",
    normal_video_swap_credits_text: "(2 kredit / video)",
    toast_2: "Terjadi kesalahan. Silakan periksa jaringan Anda dan coba lagi.",
    pay_weekly_text2: "Kredit",
    pay_weekly_text3: "Tukar dalam hitungan detik",
    faceswap_value_text1: "250+ kredit",
    pay_weekly_text1: "Mingguan",
    pay_subscribe: "BERLANGGANAN",
    faceswap_value_text3: "Tanpa Tanda Air",
    pay_monthly_text1: "Bulanan",
    faceswap_value_text2: "Lewati Antrean",
    faceswap_value_text5: "Kualitas Tinggi",
    faceswap_value_text4: "Tanpa Iklan",
    pay_monthly_text4: "Kualitas Tinggi",
    pay_monthly_text5: "Tanpa Tanda Air",
    faceswap_value_text6: "Tidak Ada Batasan",
    pay_monthly_text2: "Kredit",
    pay_monthly_text3: "Tukar dalam hitungan detik",
    faceswap_text5: "Ambil wajah",
    faceswap_text4: "Tambahkan Gambar Wajah",
    pay_monthly_text6: "Telanjang & NSFW",
    faceswap_text7: "Tukar wajah",
    faceswap_text6: "Tambahkan Gambar Target",
    faceswap_text9: "JPG/PNG",
    faceswap_text8: "Gambar Wajah",
    normal_video_swap_text1: "Maks 10 MB, Hingga 4 detik",
    pay_weekly_text6: "Telanjang & NSFW",
    pay_weekly_text4: "Kualitas Tinggi",
    ultra_video_swap_credits_text: "(10 sks /detik)",
    ultra_video_size: "Maks 50MB",
    ultra_high_quality: "Kualitas Tinggi",
    pay_weekly_text5: "Tanpa Tanda Air",
    faceswap_text1: "Kualitas Tinggi",
    faceswap_value_button_text1: "Meningkatkan",
    faceswap_text3: "Masuk",
    faceswap_text2: "Kredit",
    pay_bottom_text1: "Keamanan Dilindungi oleh",
    result_text: "Hasil",
    pay_bottom_text2: "Obrolan AI",
    pay_bottom_text3: "Batalkan kapan saja",
    tab_image_text: "Gambar",
  },
  ca: {
    pay_yearly_text1: "Anualment",
    login_text6: "Descarrega ON",
    login_text5: "per intercanviar més",
    login_text4: "ChatUp AI",
    message_info2: "Si us plau, afegiu una imatge de cara per canviar la cara.",
    login_text3: "Descarregar",
    message_info3: "Seleccioneu un producte per subscriure-us",
    credits_not_enough:
      "No hi ha prou crèdits, compra més crèdits o actualitza el teu pla.",
    login_text9: "Google Play",
    message_info1: "Si us plau, afegiu primer una imatge de destinació.",
    toast_timeout:
      "El servidor està sobrecarregat i el temps d'intercanvi de cares s'ha esgotat. Si us plau, prova de penjar un vídeo més curt.",
    login_text8: "Descarrega ON",
    login_text7: "App Store",
    select_img_toast_text: "Seleccioneu una imatge de cara.",
    pay_yearly_text9: "Nu i NSFW",
    faceswap_text10: "Imatge objectiu",
    pay_yearly_text7: "Alta qualitat",
    pay_yearly_text8: "Sense filigrana",
    toast_wrong_load:
      "S'ha produït un error en carregar el vídeo. Si us plau, proveu amb un altre fitxer.",
    pay_text3: "75% DESCOMPTE",
    pay_yearly_text5: "Crèdits",
    faceswap_text13: "Canvi de cares",
    pay_text2: "Fins a",
    pay_yearly_text6: "Canvia en segons",
    pay_text1: "Obteniu Premium",
    pay_yearly_text3: "setmana",
    faceswap_text11: "JPG/PNG",
    pay_yearly_text4: "facturat a l'any",
    faceswap_text12: "Imatges que podeu provar",
    target_video_text: "Vídeo objectiu",
    ultra_video_length: "Fins a 1 min",
    pay_text4: "per a la primera subscripció.",
    login_text2: "Per gaudir de l'intercanvi de cares il·limitat!",
    login_text1: "Inicieu la sessió",
    no_more_credits:
      "No hi ha més crèdits, actualitzeu el vostre pla per obtenir-ne més.",
    target_video_duration_text: "Menys de 6 segons",
    test: "hola món",
    videoswap_text7: "Intercanvi de vídeo",
    loading_text1: "Entre 30 segons i 5 minuts estimats",
    videoswap_text6: "Afegeix un vídeo objectiu",
    loading_text2:
      "Si us plau, mantingueu-vos a la pàgina actual o perdràs el millor resultat.",
    videoswap_text9: "MP4/MOV",
    loading_text3: "Accelera",
    web_request_error:
      "Error de xarxa, comproveu-ho i torneu-ho a provar més tard",
    toast_wrong_fromat: "Pengeu un vídeo en format MP4 o MOV.",
    toast_wrong_size: "Penja un vídeo de menys de 50 MB.",
    mobile_text2: "GRAN VENDA",
    mobile_text1: "Comença ara",
    add_video_first_text: "Si us plau, primer afegeix un vídeo de destinació.",
    toast_wrong_duration: "Penja un vídeo de menys d'1 minut.",
    pay_ultra_video_swap_text6: "Ultra Video Swap",
    ultra_video_swap_text1: "Màxim 50 MB, fins a 1 minut",
    video_ultra_text1: "Ultra",
    tab_video_text: "Vídeo",
    videoswap_text12: "Vídeos que podeu provar",
    toast_1: "Seleccioneu la imatge d'origen.",
    normal_video_swap_credits_text: "(2 crèdits/vídeo)",
    toast_2:
      "S'ha produït un error. Comprova la teva xarxa i torna-ho a provar.",
    pay_weekly_text2: "Crèdit",
    pay_weekly_text3: "Canvia en segons",
    faceswap_value_text1: "Més de 250 crèdits",
    pay_weekly_text1: "Setmanalment",
    pay_subscribe: "SUBSCRIU-TE",
    faceswap_value_text3: "Sense filigrana",
    pay_monthly_text1: "Mensualment",
    faceswap_value_text2: "Omet la cua",
    faceswap_value_text5: "Alta qualitat",
    faceswap_value_text4: "No hi ha anuncis",
    pay_monthly_text4: "Alta qualitat",
    pay_monthly_text5: "Sense filigrana",
    faceswap_value_text6: "Sense restriccions",
    pay_monthly_text2: "Crèdits",
    pay_monthly_text3: "Canvia en segons",
    faceswap_text5: "Pren la cara",
    faceswap_text4: "Afegeix una imatge de cara",
    pay_monthly_text6: "Nu i NSFW",
    faceswap_text7: "Canviar la cara",
    faceswap_text6: "Afegeix una imatge de destinació",
    faceswap_text9: "JPG/PNG",
    faceswap_text8: "Imatge de cara",
    normal_video_swap_text1: "Màxim 10 MB, fins a 4 s",
    pay_weekly_text6: "Nu i NSFW",
    pay_weekly_text4: "Alta qualitat",
    ultra_video_swap_credits_text: "(10 crèdits/segon)",
    ultra_video_size: "Màxim 50 MB",
    ultra_high_quality: "Alta qualitat",
    pay_weekly_text5: "Sense filigrana",
    faceswap_text1: "Alta qualitat",
    faceswap_value_button_text1: "Actualitza",
    faceswap_text3: "Inicieu la sessió",
    faceswap_text2: "Crèdits",
    pay_bottom_text1: "Seguretat protegida per",
    result_text: "Resultat",
    pay_bottom_text2: "ChatUp AI",
    pay_bottom_text3: "Cancel·la en qualsevol moment",
    tab_image_text: "Imatge",
  },
  zhcn: {
    pay_yearly_text1: "每年",
    login_text6: "下载开启",
    login_text5: "交换更多",
    login_text4: "聊天人工智能",
    message_info2: "请添加脸部图像以交换脸部。",
    login_text3: "下载",
    message_info3: "请选择要订阅的产品",
    credits_not_enough: "积分不足，请购买更多积分或升级您的计划。",
    login_text9: "谷歌游戏",
    message_info1: "请先添加目标图像。",
    toast_timeout: "服务器超载，换脸超时。请尝试上传较短的视频。",
    login_text8: "下载开启",
    login_text7: "应用商店",
    select_img_toast_text: "请选择脸部图像。",
    pay_yearly_text9: "裸体与不适合上班族",
    faceswap_text10: "目标图像",
    pay_yearly_text7: "高质量",
    pay_yearly_text8: "无水印",
    toast_wrong_load: "加载视频时出错。请尝试另一个文件。",
    pay_text3: "75% 折扣",
    pay_yearly_text5: "制作人员",
    faceswap_text13: "换脸",
    pay_text2: "最多",
    pay_yearly_text6: "秒换",
    pay_text1: "获得高级版",
    pay_yearly_text3: "星期",
    faceswap_text11: "JPG / PNG",
    pay_yearly_text4: "按年计费",
    faceswap_text12: "您可以尝试的图片",
    target_video_text: "目标视频",
    ultra_video_length: "长达 1 分钟",
    pay_text4: "第一次订阅。",
    login_text2: "享受无限换脸！",
    login_text1: "登入",
    no_more_credits: "没有更多积分，请升级您的计划以获得更多积分。",
    target_video_duration_text: "小于6秒",
    test: "你好世界",
    videoswap_text7: "交换视频",
    loading_text1: "预计 30 秒至 5 分钟",
    videoswap_text6: "添加目标视频",
    loading_text2: "请留在当前页面，否则您将失去最佳结果。",
    videoswap_text9: "MP4/MOV",
    loading_text3: "加快",
    web_request_error: "网络错误，请检查并稍后重试",
    toast_wrong_fromat: "请上传 MP4 或 MOV 格式的视频。",
    toast_wrong_size: "请上传小于 50MB 的视频。",
    mobile_text2: "大销售",
    mobile_text1: "现在开始",
    add_video_first_text: "请先添加目标视频。",
    toast_wrong_duration: "请上传短于1分钟的视频。",
    pay_ultra_video_swap_text6: "超视频交换",
    ultra_video_swap_text1: "最大 50MB，最长 1 分钟",
    video_ultra_text1: "极端主义者",
    tab_video_text: "视频",
    videoswap_text12: "您可以尝试的视频",
    toast_1: "请选择源图像。",
    normal_video_swap_credits_text: "（2 学分/视频）",
    toast_2: "发生错误，请检查您的网络并重试。",
    pay_weekly_text2: "信用",
    pay_weekly_text3: "秒换",
    faceswap_value_text1: "250+ 学分",
    pay_weekly_text1: "每周",
    pay_subscribe: "订阅",
    faceswap_value_text3: "无水印",
    pay_monthly_text1: "每月",
    faceswap_value_text2: "跳过队列",
    faceswap_value_text5: "高质量",
    faceswap_value_text4: "无广告",
    pay_monthly_text4: "高质量",
    pay_monthly_text5: "无水印",
    faceswap_value_text6: "无限制",
    pay_monthly_text2: "制作人员",
    pay_monthly_text3: "秒换",
    faceswap_text5: "拿脸",
    faceswap_text4: "添加人脸图像",
    pay_monthly_text6: "裸体与不适合上班族",
    faceswap_text7: "换脸",
    faceswap_text6: "添加目标图像",
    faceswap_text9: "JPG / PNG",
    faceswap_text8: "人脸图像",
    normal_video_swap_text1: "最大 10MB，最长 4 秒",
    pay_weekly_text6: "裸体与不适合上班族",
    pay_weekly_text4: "高质量",
    ultra_video_swap_credits_text: "（10 学分/秒）",
    ultra_video_size: "最大50MB",
    ultra_high_quality: "高质量",
    pay_weekly_text5: "无水印",
    faceswap_text1: "高质量",
    faceswap_value_button_text1: "升级",
    faceswap_text3: "登入",
    faceswap_text2: "制作人员",
    pay_bottom_text1: "安全保护",
    result_text: "结果",
    pay_bottom_text2: "聊天人工智能",
    pay_bottom_text3: "随时取消",
    tab_image_text: "图像",
  },
  sv: {
    pay_yearly_text1: "Årlig",
    login_text6: "Ladda ner PÅ",
    login_text5: "att byta mer",
    login_text4: "ChatUp AI",
    message_info2: "Lägg till en ansiktsbild för att byta ansikte.",
    login_text3: "Ladda ner",
    message_info3: "Välj en produkt att prenumerera på",
    credits_not_enough:
      "Inte tillräckligt med krediter, köp fler krediter eller uppgradera din plan.",
    login_text9: "Google Play",
    message_info1: "Lägg till en målbild först.",
    toast_timeout:
      "Servern är överbelastad och ansiktsbytet tog timeout. Försök att ladda upp en kortare video.",
    login_text8: "Ladda ner PÅ",
    login_text7: "App Store",
    select_img_toast_text: "Välj en ansiktsbild.",
    pay_yearly_text9: "Naken & NSFW",
    faceswap_text10: "Målbild",
    pay_yearly_text7: "Hög kvalitet",
    pay_yearly_text8: "Inget vattenmärke",
    toast_wrong_load:
      "Det gick inte att ladda videon. Försök med en annan fil.",
    pay_text3: "75 % RABATT",
    pay_yearly_text5: "Krediter",
    faceswap_text13: "Ansiktsbyte",
    pay_text2: "Fram till",
    pay_yearly_text6: "Byt på några sekunder",
    pay_text1: "Få Premium",
    pay_yearly_text3: "vecka",
    faceswap_text11: "JPG/PNG",
    pay_yearly_text4: "faktureras ett år",
    faceswap_text12: "Bilder du kan prova",
    target_video_text: "Målvideo",
    ultra_video_length: "Upp till 1 min",
    pay_text4: "för den första prenumerationen.",
    login_text2: "För att njuta av obegränsad Face Swap!",
    login_text1: "Logga in",
    no_more_credits:
      "Det finns inga fler krediter, uppgradera din plan för att få fler.",
    target_video_duration_text: "Mindre än 6s",
    test: "hej världen",
    videoswap_text7: "Byt video",
    loading_text1: "30 sekunder till 5 minuter uppskattat",
    videoswap_text6: "Lägg till målvideo",
    loading_text2:
      "Vänligen stanna kvar på den aktuella sidan annars kommer du att förlora det bästa resultatet.",
    videoswap_text9: "MP4 / MOV",
    loading_text3: "Snabba upp",
    web_request_error: "Nätverksfel, kontrollera och försök igen senare",
    toast_wrong_fromat: "Ladda upp en video i MP4- eller MOV-format.",
    toast_wrong_size: "Ladda upp en video som är mindre än 50 MB.",
    mobile_text2: "STOR FÖRSÄLJNING",
    mobile_text1: "Börja nu",
    add_video_first_text: "Lägg till en målvideo först.",
    toast_wrong_duration: "Ladda upp ett videoklipp som är kortare än 1 minut.",
    pay_ultra_video_swap_text6: "Ultra Video Swap",
    ultra_video_swap_text1: "Max 50 MB, upp till 1 min",
    video_ultra_text1: "Ultra",
    tab_video_text: "Video",
    videoswap_text12: "Videor du kan prova",
    toast_1: "Välj källbild.",
    normal_video_swap_credits_text: "(2 poäng/video)",
    toast_2: "Något fel hände, kontrollera ditt nätverk och försök igen.",
    pay_weekly_text2: "Kreditera",
    pay_weekly_text3: "Byt på några sekunder",
    faceswap_value_text1: "250+ poäng",
    pay_weekly_text1: "Varje vecka",
    pay_subscribe: "PRENUMERERA",
    faceswap_value_text3: "Inget vattenmärke",
    pay_monthly_text1: "Månatlig",
    faceswap_value_text2: "Hoppa över kö",
    faceswap_value_text5: "Hög kvalitet",
    faceswap_value_text4: "Inga annonser",
    pay_monthly_text4: "Hög kvalitet",
    pay_monthly_text5: "Inget vattenmärke",
    faceswap_value_text6: "Inga begränsningar",
    pay_monthly_text2: "Krediter",
    pay_monthly_text3: "Byt på några sekunder",
    faceswap_text5: "Ta ansiktet",
    faceswap_text4: "Lägg till ansiktsbild",
    pay_monthly_text6: "Naken & NSFW",
    faceswap_text7: "Byt ansikte",
    faceswap_text6: "Lägg till målbild",
    faceswap_text9: "JPG/PNG",
    faceswap_text8: "Ansiktsbild",
    normal_video_swap_text1: "Max 10MB, upp till 4s",
    pay_weekly_text6: "Naken & NSFW",
    pay_weekly_text4: "Hög kvalitet",
    ultra_video_swap_credits_text: "(10 poäng/sekund)",
    ultra_video_size: "Max 50 MB",
    ultra_high_quality: "Hög kvalitet",
    pay_weekly_text5: "Inget vattenmärke",
    faceswap_text1: "Hög kvalitet",
    faceswap_value_button_text1: "Uppgradera",
    faceswap_text3: "Logga in",
    faceswap_text2: "Krediter",
    pay_bottom_text1: "Säkerhet Skyddad av",
    result_text: "Resultat",
    pay_bottom_text2: "ChatUp AI",
    pay_bottom_text3: "Avbryt när som helst",
    tab_image_text: "Bild",
  },
  ko: {
    pay_yearly_text1: "매년",
    login_text6: "다운로드 켜기",
    login_text5: "더 교환하려면",
    login_text4: "챗업 AI",
    message_info2: "얼굴을 바꾸려면 얼굴 이미지를 추가하세요.",
    login_text3: "다운로드",
    message_info3: "구독할 제품을 선택하세요.",
    credits_not_enough:
      "크레딧이 부족합니다. 크레딧을 더 구매하거나 요금제를 업그레이드하세요.",
    login_text9: "구글 플레이",
    message_info1: "먼저 대상 이미지를 추가하세요.",
    toast_timeout:
      "서버가 과부하되어 얼굴 교체 시간이 초과되었습니다. 더 짧은 동영상을 업로드해 보세요.",
    login_text8: "다운로드 켜기",
    login_text7: "앱스토어",
    select_img_toast_text: "얼굴 이미지를 선택해 주세요.",
    pay_yearly_text9: "누드 & NSFW",
    faceswap_text10: "대상 이미지",
    pay_yearly_text7: "고품질",
    pay_yearly_text8: "워터마크 없음",
    toast_wrong_load:
      "동영상을 로드하는 중에 오류가 발생했습니다. 다른 파일을 사용해 보세요.",
    pay_text3: "75% 할인",
    pay_yearly_text5: "크레딧",
    faceswap_text13: "얼굴 교환",
    pay_text2: "최대",
    pay_yearly_text6: "몇 초 만에 교체",
    pay_text1: "프리미엄 받기",
    pay_yearly_text3: "주",
    faceswap_text11: "JPG / PNG",
    pay_yearly_text4: "1년 단위로 청구됨",
    faceswap_text12: "시도해 볼 수 있는 이미지",
    target_video_text: "대상 비디오",
    ultra_video_length: "최대 1분",
    pay_text4: "첫 번째 구독의 경우.",
    login_text2: "무제한 얼굴 교환을 즐기려면!",
    login_text1: "로그인",
    no_more_credits:
      "더 이상 크레딧이 없습니다. 더 많은 크레딧을 얻으려면 요금제를 업그레이드하세요.",
    target_video_duration_text: "6초 미만",
    test: "안녕 세계",
    videoswap_text7: "비디오 교환",
    loading_text1: "30초~5분 예상",
    videoswap_text6: "대상 비디오 추가",
    loading_text2: "현재 페이지에 머물지 않으면 최상의 결과를 잃게 됩니다.",
    videoswap_text9: "MP4 / MOV",
    loading_text3: "속도 향상",
    web_request_error:
      "네트워크 오류입니다. 확인하고 나중에 다시 시도해 주세요.",
    toast_wrong_fromat: "MP4 또는 MOV 형식의 동영상을 업로드해 주세요.",
    toast_wrong_size: "50MB 이하의 영상을 업로드해주세요.",
    mobile_text2: "큰 판매",
    mobile_text1: "지금 시작하세요",
    add_video_first_text: "먼저 대상 동영상을 추가하세요.",
    toast_wrong_duration: "1분 이내의 짧은 영상을 올려주세요.",
    pay_ultra_video_swap_text6: "울트라 비디오 스왑",
    ultra_video_swap_text1: "최대 50MB, 최대 1분",
    video_ultra_text1: "극단론자",
    tab_video_text: "동영상",
    videoswap_text12: "시도해 볼 수 있는 동영상",
    toast_1: "원본 이미지를 선택해주세요.",
    normal_video_swap_credits_text: "(2학점/동영상)",
    toast_2: "문제가 발생했습니다. 네트워크를 확인하고 다시 시도하세요.",
    pay_weekly_text2: "신용 거래",
    pay_weekly_text3: "몇 초 만에 교체",
    faceswap_value_text1: "250+ 크레딧",
    pay_weekly_text1: "주간",
    pay_subscribe: "구독하다",
    faceswap_value_text3: "워터마크 없음",
    pay_monthly_text1: "월간 간행물",
    faceswap_value_text2: "대기열 건너뛰기",
    faceswap_value_text5: "고품질",
    faceswap_value_text4: "광고 없음",
    pay_monthly_text4: "고품질",
    pay_monthly_text5: "워터마크 없음",
    faceswap_value_text6: "제한 없음",
    pay_monthly_text2: "크레딧",
    pay_monthly_text3: "몇 초 만에 교체",
    faceswap_text5: "얼굴을 가져라",
    faceswap_text4: "얼굴 이미지 추가",
    pay_monthly_text6: "누드 & NSFW",
    faceswap_text7: "얼굴 바꾸기",
    faceswap_text6: "대상 이미지 추가",
    faceswap_text9: "JPG / PNG",
    faceswap_text8: "얼굴 이미지",
    normal_video_swap_text1: "최대 10MB, 최대 4초",
    pay_weekly_text6: "누드 & NSFW",
    pay_weekly_text4: "고품질",
    ultra_video_swap_credits_text: "(10크레딧/초)",
    ultra_video_size: "최대 50MB",
    ultra_high_quality: "고품질",
    pay_weekly_text5: "워터마크 없음",
    faceswap_text1: "고품질",
    faceswap_value_button_text1: "치받이",
    faceswap_text3: "로그인",
    faceswap_text2: "크레딧",
    pay_bottom_text1: "보안 보호 대상",
    result_text: "결과",
    pay_bottom_text2: "챗업 AI",
    pay_bottom_text3: "언제든지 취소",
    tab_image_text: "영상",
  },
  ms: {
    pay_yearly_text1: "setiap tahun",
    login_text6: "Muat turun HIDUP",
    login_text5: "untuk Tukar Lagi",
    login_text4: "ChatUp AI",
    message_info2: "Sila tambahkan imej muka untuk menukar muka.",
    login_text3: "Muat turun",
    message_info3: "Sila pilih produk untuk melanggan",
    credits_not_enough:
      "Tiada kredit yang mencukupi, sila beli lebih banyak kredit atau tingkatkan pelan anda.",
    login_text9: "Google Play",
    message_info1: "Sila tambah imej sasaran terlebih dahulu.",
    toast_timeout:
      "Pelayan terlebih beban dan pertukaran muka tamat masa. Sila cuba muat naik video yang lebih pendek.",
    login_text8: "Muat turun HIDUP",
    login_text7: "App Store",
    select_img_toast_text: "Sila pilih imej muka.",
    pay_yearly_text9: "Bogel & NSFW",
    faceswap_text10: "Imej Sasaran",
    pay_yearly_text7: "Kualiti Tinggi",
    pay_yearly_text8: "Tiada Tera Air",
    toast_wrong_load: "Ralat memuatkan video. Sila cuba fail lain.",
    pay_text3: "DISKAUN 75%.",
    pay_yearly_text5: "Kredit",
    faceswap_text13: "Tukar Muka",
    pay_text2: "Sehingga",
    pay_yearly_text6: "Tukar dalam beberapa saat",
    pay_text1: "Dapatkan Premium",
    pay_yearly_text3: "minggu",
    faceswap_text11: "JPG / PNG",
    pay_yearly_text4: "dibilkan setahun",
    faceswap_text12: "Imej yang anda boleh cuba",
    target_video_text: "Video Sasaran",
    ultra_video_length: "Sehingga 1minit",
    pay_text4: "untuk langganan pertama.",
    login_text2: "Untuk menikmati Tukar Wajah tanpa had!",
    login_text1: "Log Masuk",
    no_more_credits:
      "Tiada lagi kredit, sila tingkatkan rancangan anda untuk mendapatkan lebih banyak.",
    target_video_duration_text: "Kurang daripada 6s",
    test: "hello dunia",
    videoswap_text7: "Tukar Video",
    loading_text1: "30 saat hingga 5 minit dianggarkan",
    videoswap_text6: "Tambah Video Sasaran",
    loading_text2:
      "Sila kekal di halaman semasa atau anda akan kehilangan hasil terbaik.",
    videoswap_text9: "MP4 / MOV",
    loading_text3: "Percepatkan",
    web_request_error: "Ralat rangkaian, sila semak dan cuba lagi kemudian",
    toast_wrong_fromat: "Sila muat naik video berformat MP4 atau MOV.",
    toast_wrong_size: "Sila muat naik video yang lebih kecil daripada 50MB.",
    mobile_text2: "JUALAN BESAR",
    mobile_text1: "Mulakan Sekarang",
    add_video_first_text: "Sila tambahkan video sasaran terlebih dahulu.",
    toast_wrong_duration:
      "Sila muat naik video yang lebih pendek daripada 1 minit.",
    pay_ultra_video_swap_text6: "Tukar Video Ultra",
    ultra_video_swap_text1: "Max 50MB, Sehingga 1minit",
    video_ultra_text1: "Ultra",
    tab_video_text: "Video",
    videoswap_text12: "Video yang boleh anda cuba",
    toast_1: "Sila pilih imej sumber.",
    normal_video_swap_credits_text: "(2 kredit /video)",
    toast_2:
      "Kesilapan telah berlaku, sila semak rangkaian anda dan cuba semula.",
    pay_weekly_text2: "Kredit",
    pay_weekly_text3: "Tukar dalam beberapa saat",
    faceswap_value_text1: "250+ kredit",
    pay_weekly_text1: "Mingguan",
    pay_subscribe: "LANGGAN",
    faceswap_value_text3: "Tiada Tera Air",
    pay_monthly_text1: "Bulanan",
    faceswap_value_text2: "Langkau Baris Gilir",
    faceswap_value_text5: "Kualiti Tinggi",
    faceswap_value_text4: "Tiada Iklan",
    pay_monthly_text4: "Kualiti Tinggi",
    pay_monthly_text5: "Tiada Tera Air",
    faceswap_value_text6: "Tiada Sekatan",
    pay_monthly_text2: "Kredit",
    pay_monthly_text3: "Tukar dalam beberapa saat",
    faceswap_text5: "Ambil muka",
    faceswap_text4: "Tambah Imej Wajah",
    pay_monthly_text6: "Bogel & NSFW",
    faceswap_text7: "Tukar muka",
    faceswap_text6: "Tambah Imej Sasaran",
    faceswap_text9: "JPG / PNG",
    faceswap_text8: "Imej Muka",
    normal_video_swap_text1: "Max 10MB, Sehingga 4s",
    pay_weekly_text6: "Bogel & NSFW",
    pay_weekly_text4: "Kualiti Tinggi",
    ultra_video_swap_credits_text: "(10 kredit /saat)",
    ultra_video_size: "Maks 50MB",
    ultra_high_quality: "Kualiti Tinggi",
    pay_weekly_text5: "Tiada Tera Air",
    faceswap_text1: "Kualiti Tinggi",
    faceswap_value_button_text1: "Naik taraf",
    faceswap_text3: "Log Masuk",
    faceswap_text2: "Kredit",
    pay_bottom_text1: "Keselamatan Dilindungi oleh",
    result_text: "Hasilnya",
    pay_bottom_text2: "ChatUp AI",
    pay_bottom_text3: "Batalkan bila-bila masa",
    tab_image_text: "Imej",
  },
  el: {
    pay_yearly_text1: "Ετήσια",
    login_text6: "Λήψη ΕΝΕΡΓΟΠΟΙΗΣΗ",
    login_text5: "για να ανταλλάξετε περισσότερα",
    login_text4: "ChatUp AI",
    message_info2: "Προσθέστε μια εικόνα προσώπου για να αλλάξετε πρόσωπο.",
    login_text3: "Λήψη",
    message_info3: "Επιλέξτε ένα προϊόν για εγγραφή",
    credits_not_enough:
      "Δεν υπάρχουν αρκετές πιστώσεις, αγοράστε περισσότερες πιστώσεις ή αναβαθμίστε το σχέδιό σας.",
    login_text9: "Google Play",
    message_info1: "Προσθέστε πρώτα μια εικόνα-στόχο.",
    toast_timeout:
      "Ο διακομιστής είναι υπερφορτωμένος και το χρονικό όριο της αλλαγής προσώπου έληξε. Δοκιμάστε να ανεβάσετε ένα μικρότερο βίντεο.",
    login_text8: "Λήψη ΕΝΕΡΓΟΠΟΙΗΣΗ",
    login_text7: "App Store",
    select_img_toast_text: "Επιλέξτε μια εικόνα προσώπου.",
    pay_yearly_text9: "Γυμνό & NSFW",
    faceswap_text10: "Εικόνα στόχου",
    pay_yearly_text7: "Υψηλής Ποιότητας",
    pay_yearly_text8: "Χωρίς υδατογράφημα",
    toast_wrong_load:
      "Σφάλμα κατά τη φόρτωση του βίντεο. Δοκιμάστε άλλο αρχείο.",
    pay_text3: "ΕΚΠΤΩΣΗ 75%.",
    pay_yearly_text5: "Πιστώσεις",
    faceswap_text13: "Εναλλαγή προσώπου",
    pay_text2: "Μέχρι και",
    pay_yearly_text6: "Ανταλλαγή σε δευτερόλεπτα",
    pay_text1: "Αποκτήστε Premium",
    pay_yearly_text3: "εβδομάδα",
    faceswap_text11: "JPG / PNG",
    pay_yearly_text4: "τιμολογείται ένα χρόνο",
    faceswap_text12: "Εικόνες που μπορείτε να δοκιμάσετε",
    target_video_text: "Στόχος βίντεο",
    ultra_video_length: "Έως 1 λεπτό",
    pay_text4: "για την πρώτη συνδρομή.",
    login_text2: "Για να απολαύσετε απεριόριστο Face Swap!",
    login_text1: "Είσοδος",
    no_more_credits:
      "Δεν υπάρχουν άλλες πιστώσεις, αναβαθμίστε το σχέδιό σας για να λάβετε περισσότερες.",
    target_video_duration_text: "Λιγότερο από 6 δευτ",
    test: "γεια σου κόσμε",
    videoswap_text7: "Ανταλλαγή βίντεο",
    loading_text1: "Εκτιμώμενα 30 δευτερόλεπτα έως 5 λεπτά",
    videoswap_text6: "Προσθήκη βίντεο στόχου",
    loading_text2:
      "Παρακαλούμε μείνετε στην τρέχουσα σελίδα διαφορετικά θα χάσετε το καλύτερο αποτέλεσμα.",
    videoswap_text9: "MP4 / MOV",
    loading_text3: "Επιταχύνετε",
    web_request_error: "Σφάλμα δικτύου, ελέγξτε και δοκιμάστε ξανά αργότερα",
    toast_wrong_fromat: "Ανεβάστε ένα βίντεο σε μορφή MP4 ή MOV.",
    toast_wrong_size: "Ανεβάστε ένα βίντεο μικρότερο από 50 MB.",
    mobile_text2: "ΜΕΓΑΛΕΣ ΠΩΛΗΣΕΙΣ",
    mobile_text1: "Ξεκινήστε τώρα",
    add_video_first_text: "Προσθέστε πρώτα ένα βίντεο-στόχο.",
    toast_wrong_duration: "Ανεβάστε ένα βίντεο μικρότερο από 1 λεπτό.",
    pay_ultra_video_swap_text6: "Ultra Video Swap",
    ultra_video_swap_text1: "Μέγιστο 50MB, Έως 1 λεπτό",
    video_ultra_text1: "Υπερ",
    tab_video_text: "Βίντεο",
    videoswap_text12: "Βίντεο που μπορείτε να δοκιμάσετε",
    toast_1: "Επιλέξτε την εικόνα πηγής.",
    normal_video_swap_credits_text: "(2 μονάδες /βίντεο)",
    toast_2: "Συνέβη κάτι λάθος, ελέγξτε το δίκτυό σας και δοκιμάστε ξανά.",
    pay_weekly_text2: "Πίστωση",
    pay_weekly_text3: "Ανταλλαγή σε δευτερόλεπτα",
    faceswap_value_text1: "250+ πιστώσεις",
    pay_weekly_text1: "Εβδομαδιαίος",
    pay_subscribe: "ΣΥΝΕΙΣΦΕΡΩ",
    faceswap_value_text3: "Χωρίς υδατογράφημα",
    pay_monthly_text1: "Μηνιαίος",
    faceswap_value_text2: "Παράλειψη ουράς",
    faceswap_value_text5: "Υψηλής Ποιότητας",
    faceswap_value_text4: "Χωρίς διαφημίσεις",
    pay_monthly_text4: "Υψηλής Ποιότητας",
    pay_monthly_text5: "Χωρίς υδατογράφημα",
    faceswap_value_text6: "Χωρίς περιορισμούς",
    pay_monthly_text2: "Πιστώσεις",
    pay_monthly_text3: "Ανταλλαγή σε δευτερόλεπτα",
    faceswap_text5: "Πάρε πρόσωπο",
    faceswap_text4: "Προσθήκη εικόνας προσώπου",
    pay_monthly_text6: "Γυμνό & NSFW",
    faceswap_text7: "Ανταλλαγή προσώπου",
    faceswap_text6: "Προσθήκη εικόνας στόχου",
    faceswap_text9: "JPG / PNG",
    faceswap_text8: "Εικόνα προσώπου",
    normal_video_swap_text1: "Μέγιστο 10MB, Έως 4 δευτερόλεπτα",
    pay_weekly_text6: "Γυμνό & NSFW",
    pay_weekly_text4: "Υψηλής Ποιότητας",
    ultra_video_swap_credits_text: "(10 μονάδες /δευτερόλεπτο)",
    ultra_video_size: "Μέγιστο 50MB",
    ultra_high_quality: "Υψηλής Ποιότητας",
    pay_weekly_text5: "Χωρίς υδατογράφημα",
    faceswap_text1: "Υψηλής Ποιότητας",
    faceswap_value_button_text1: "Αναβαθμίζω",
    faceswap_text3: "Είσοδος",
    faceswap_text2: "Πιστώσεις",
    pay_bottom_text1: "Ασφάλεια Προστατεύεται από",
    result_text: "Αποτέλεσμα",
    pay_bottom_text2: "ChatUp AI",
    pay_bottom_text3: "Ακύρωση ανά πάσα στιγμή",
    tab_image_text: "Εικών",
  },
  en: {
    pay_yearly_text1: "Yearly",
    login_text6: "Download ON",
    login_text5: "to Swap More",
    login_text4: "ChatUp AI",
    message_info2: "Please add a face image to swap face.",
    login_text3: "Download",
    message_info3: "Please select a product to subscribe",
    credits_not_enough:
      "No enough credits, please purchase more credits or upgrade your plan.",
    login_text9: "Google Play",
    message_info1: "Please add a target image first.",
    toast_timeout:
      "The server is overloaded and the face swap timed out. Please try uploading a shorter video.",
    login_text8: "Download ON",
    login_text7: "App Store",
    select_img_toast_text: "Please select a face image.",
    pay_yearly_text9: "Nude & NSFW",
    faceswap_text10: "Target Image",
    pay_yearly_text7: "High Quality",
    pay_yearly_text8: "No Watermark",
    toast_wrong_load: "Error loading video. Please try another file.",
    pay_text3: "75% OFF",
    pay_yearly_text5: "Credits",
    faceswap_text13: "Face Swap",
    pay_text2: "Up to",
    pay_yearly_text6: "Swap in seconds",
    pay_text1: "Get Premium",
    pay_yearly_text3: "week",
    faceswap_text11: "JPG / PNG",
    pay_yearly_text4: "billed a year",
    faceswap_text12: "Images you may try",
    target_video_text: "Target Video",
    ultra_video_length: "Up to 1mins",
    pay_text4: "for the first subscription.",
    login_text2: "To enjoy unlimited Face Swap!",
    login_text1: "Sign In",
    no_more_credits:
      "There is no more credits, please upgrade your plan to get more.",
    target_video_duration_text: "Less than 6s",
    test: "hello world",
    videoswap_text7: "Swap Video",
    loading_text1: "30 seconds to 5mins estimated",
    videoswap_text6: "Add Target Video",
    loading_text2:
      "Please stay in the current page or you will lose the best result.",
    videoswap_text9: "MP4 / MOV",
    loading_text3: "Speed Up",
    web_request_error: "Network error, please check and try again later",
    toast_wrong_fromat: "Please upload an MP4 or MOV format video.",
    toast_wrong_size: "Please upload a video smaller than 50MB.",
    mobile_text2: "BIG SALES",
    mobile_text1: "Start Now",
    add_video_first_text: "Please add a target video first.",
    toast_wrong_duration: "Please upload a video shorter than 1 minutes.",
    pay_ultra_video_swap_text6: "Ultra Video Swap",
    ultra_video_swap_text1: "Max 50MB, Up to 1mins",
    video_ultra_text1: "Ultra",
    tab_video_text: "Video",
    videoswap_text12: "Videos you may try",
    toast_1: "Please select the source image.",
    normal_video_swap_credits_text: "(2 credits /video)",
    toast_2: "Something wrong happened, please check your network and retry.",
    pay_weekly_text2: "Credit",
    pay_weekly_text3: "Swap in seconds",
    faceswap_value_text1: "250+ credits",
    pay_weekly_text1: "Weekly",
    pay_subscribe: "SUBSCRIBE",
    faceswap_value_text3: "No Watermark",
    pay_monthly_text1: "Monthly",
    faceswap_value_text2: "Skip Queue",
    faceswap_value_text5: "High Quality",
    faceswap_value_text4: "No Ads",
    pay_monthly_text4: "High Quality",
    pay_monthly_text5: "No Watermark",
    faceswap_value_text6: "No Restrictions",
    pay_monthly_text2: "Credits",
    pay_monthly_text3: "Swap in seconds",
    faceswap_text5: "Take face",
    faceswap_text4: "Add Face Image",
    pay_monthly_text6: "Nude & NSFW",
    faceswap_text7: "Swap face",
    faceswap_text6: "Add Target Image",
    faceswap_text9: "JPG / PNG",
    faceswap_text8: "Face Image",
    normal_video_swap_text1: "Max 10MB, Up to 4s",
    pay_weekly_text6: "Nude & NSFW",
    pay_weekly_text4: "High Quality",
    ultra_video_swap_credits_text: "(10 credits /second)",
    ultra_video_size: "Max 50MB",
    ultra_high_quality: "High Quality",
    pay_weekly_text5: "No Watermark",
    faceswap_text1: "High Quality",
    faceswap_value_button_text1: "Upgrade",
    faceswap_text3: "Sign In",
    faceswap_text2: "Credits",
    pay_bottom_text1: "Security Protected by",
    result_text: "Result",
    pay_bottom_text2: "ChatUp AI",
    pay_bottom_text3: "Cancel anytime",
    tab_image_text: "Image",
  },
  it: {
    pay_yearly_text1: "Annuale",
    login_text6: "Scarica ON",
    login_text5: "per scambiare di più",
    login_text4: "ChatUp IA",
    message_info2: "Aggiungi un'immagine del volto per scambiare il volto.",
    login_text3: "Scaricamento",
    message_info3: "Seleziona un prodotto per iscriverti",
    credits_not_enough:
      "Crediti insufficienti, acquista più crediti o aggiorna il tuo piano.",
    login_text9: "Google Play",
    message_info1: "Aggiungi prima un'immagine di destinazione.",
    toast_timeout:
      "Il server è sovraccarico e lo scambio di volti è scaduto. Prova a caricare un video più breve.",
    login_text8: "Scarica ON",
    login_text7: "App Store",
    select_img_toast_text: "Seleziona un'immagine del viso.",
    pay_yearly_text9: "Nudo e NSFW",
    faceswap_text10: "Immagine di destinazione",
    pay_yearly_text7: "Alta qualità",
    pay_yearly_text8: "Nessuna filigrana",
    toast_wrong_load:
      "Errore durante il caricamento del video. Prova con un altro file.",
    pay_text3: "SCONTO DEL 75%.",
    pay_yearly_text5: "Crediti",
    faceswap_text13: "Scambio di volti",
    pay_text2: "Fino a",
    pay_yearly_text6: "Scambia in pochi secondi",
    pay_text1: "Ottieni Premium",
    pay_yearly_text3: "settimana",
    faceswap_text11: "JPG/PNG",
    pay_yearly_text4: "fatturato un anno",
    faceswap_text12: "Immagini che potresti provare",
    target_video_text: "Video di destinazione",
    ultra_video_length: "Fino a 1 minuto",
    pay_text4: "per il primo abbonamento.",
    login_text2: "Per goderti il ​​Face Swap illimitato!",
    login_text1: "Registrazione",
    no_more_credits:
      "Non ci sono più crediti, aggiorna il tuo piano per ottenerne di più.",
    target_video_duration_text: "Meno di 6 secondi",
    test: "Ciao mondo",
    videoswap_text7: "Scambia video",
    loading_text1: "Da 30 secondi a 5 minuti stimati",
    videoswap_text6: "Aggiungi video di destinazione",
    loading_text2:
      "Rimani nella pagina corrente altrimenti perderai il risultato migliore.",
    videoswap_text9: "MP4/MOV",
    loading_text3: "Accelerare",
    web_request_error: "Errore di rete, controlla e riprova più tardi",
    toast_wrong_fromat: "Carica un video in formato MP4 o MOV.",
    toast_wrong_size: "Carica un video di dimensioni inferiori a 50 MB.",
    mobile_text2: "GRANDI VENDITE",
    mobile_text1: "Inizia ora",
    add_video_first_text: "Aggiungi prima un video di destinazione.",
    toast_wrong_duration: "Carica un video di durata inferiore a 1 minuto.",
    pay_ultra_video_swap_text6: "Scambio video ultra",
    ultra_video_swap_text1: "Massimo 50 MB, fino a 1 minuto",
    video_ultra_text1: "Ultra",
    tab_video_text: "Video",
    videoswap_text12: "Video che potresti provare",
    toast_1: "Seleziona l'immagine di origine.",
    normal_video_swap_credits_text: "(2 crediti/video)",
    toast_2:
      "È successo qualcosa di sbagliato, controlla la tua rete e riprova.",
    pay_weekly_text2: "Credito",
    pay_weekly_text3: "Scambia in pochi secondi",
    faceswap_value_text1: "Oltre 250 crediti",
    pay_weekly_text1: "Settimanale",
    pay_subscribe: "ISCRIVITI",
    faceswap_value_text3: "Nessuna filigrana",
    pay_monthly_text1: "Mensile",
    faceswap_value_text2: "Salta la coda",
    faceswap_value_text5: "Alta qualità",
    faceswap_value_text4: "Nessuna pubblicità",
    pay_monthly_text4: "Alta qualità",
    pay_monthly_text5: "Nessuna filigrana",
    faceswap_value_text6: "Nessuna restrizione",
    pay_monthly_text2: "Crediti",
    pay_monthly_text3: "Scambia in pochi secondi",
    faceswap_text5: "Prendi la faccia",
    faceswap_text4: "Aggiungi l'immagine del viso",
    pay_monthly_text6: "Nudo e NSFW",
    faceswap_text7: "Scambia faccia",
    faceswap_text6: "Aggiungi immagine di destinazione",
    faceswap_text9: "JPG/PNG",
    faceswap_text8: "Immagine del volto",
    normal_video_swap_text1: "Massimo 10 MB, fino a 4 secondi",
    pay_weekly_text6: "Nudo e NSFW",
    pay_weekly_text4: "Alta qualità",
    ultra_video_swap_credits_text: "(10 crediti/secondo)",
    ultra_video_size: "Massimo 50 MB",
    ultra_high_quality: "Alta qualità",
    pay_weekly_text5: "Nessuna filigrana",
    faceswap_text1: "Alta qualità",
    faceswap_value_button_text1: "Aggiornamento",
    faceswap_text3: "Registrazione",
    faceswap_text2: "Crediti",
    pay_bottom_text1: "Sicurezza protetta da",
    result_text: "Risultato",
    pay_bottom_text2: "ChatUp IA",
    pay_bottom_text3: "Annulla in qualsiasi momento",
    tab_image_text: "Immagine",
  },
  es: {
    pay_yearly_text1: "Anual",
    login_text6: "Descargar ON",
    login_text5: "para intercambiar más",
    login_text4: "ChatUp AI",
    message_info2: "Agregue una imagen de cara para intercambiar cara.",
    login_text3: "Descargar",
    message_info3: "Por favor seleccione un producto para suscribirse",
    credits_not_enough:
      "No hay suficientes créditos, compre más créditos o actualice su plan.",
    login_text9: "GooglePlay",
    message_info1: "Primero agregue una imagen de destino.",
    toast_timeout:
      "El servidor está sobrecargado y se agotó el tiempo de espera para el intercambio de caras. Intente subir un vídeo más corto.",
    login_text8: "Descargar ON",
    login_text7: "Tienda de aplicaciones",
    select_img_toast_text: "Por favor seleccione una imagen de cara.",
    pay_yearly_text9: "Desnudo y NSFW",
    faceswap_text10: "Imagen de destino",
    pay_yearly_text7: "Alta calidad",
    pay_yearly_text8: "Sin marca de agua",
    toast_wrong_load: "Error al cargar el vídeo. Pruebe con otro archivo.",
    pay_text3: "75% DE DESCUENTO",
    pay_yearly_text5: "Créditos",
    faceswap_text13: "Intercambio de caras",
    pay_text2: "Arriba a",
    pay_yearly_text6: "Intercambiar en segundos",
    pay_text1: "Obtener prima",
    pay_yearly_text3: "semana",
    faceswap_text11: "JPEG/PNG",
    pay_yearly_text4: "facturado al año",
    faceswap_text12: "Imágenes que puedes probar",
    target_video_text: "Vídeo de destino",
    ultra_video_length: "Hasta 1 minuto",
    pay_text4: "para la primera suscripción.",
    login_text2: "¡Para disfrutar de Face Swap ilimitado!",
    login_text1: "Iniciar sesión",
    no_more_credits: "No hay más créditos, actualice su plan para obtener más.",
    target_video_duration_text: "Menos de 6s",
    test: "Hola Mundo",
    videoswap_text7: "Intercambiar vídeo",
    loading_text1: "30 segundos a 5 minutos estimados",
    videoswap_text6: "Agregar video de destino",
    loading_text2:
      "Permanezca en la página actual o perderá el mejor resultado.",
    videoswap_text9: "MP4/MOV",
    loading_text3: "Acelerar",
    web_request_error:
      "Error de red, verifíquelo e inténtelo nuevamente más tarde.",
    toast_wrong_fromat: "Sube un vídeo en formato MP4 o MOV.",
    toast_wrong_size: "Sube un vídeo de menos de 50 MB.",
    mobile_text2: "GRANDES VENTAS",
    mobile_text1: "Empezar ahora",
    add_video_first_text: "Primero agregue un video de destino.",
    toast_wrong_duration: "Sube un vídeo de menos de 1 minuto.",
    pay_ultra_video_swap_text6: "Intercambio de vídeo ultra",
    ultra_video_swap_text1: "Máximo 50 MB, hasta 1 minuto",
    video_ultra_text1: "Ultra",
    tab_video_text: "Video",
    videoswap_text12: "Vídeos que puedes probar",
    toast_1: "Seleccione la imagen de origen.",
    normal_video_swap_credits_text: "(2 créditos/vídeo)",
    toast_2: "Ocurrió algo mal, verifique su red y vuelva a intentarlo.",
    pay_weekly_text2: "Crédito",
    pay_weekly_text3: "Intercambiar en segundos",
    faceswap_value_text1: "250+ créditos",
    pay_weekly_text1: "Semanalmente",
    pay_subscribe: "SUSCRIBIR",
    faceswap_value_text3: "Sin marca de agua",
    pay_monthly_text1: "Mensual",
    faceswap_value_text2: "Saltar cola",
    faceswap_value_text5: "Alta calidad",
    faceswap_value_text4: "Sin anuncios",
    pay_monthly_text4: "Alta calidad",
    pay_monthly_text5: "Sin marca de agua",
    faceswap_value_text6: "Sin restricciones",
    pay_monthly_text2: "Créditos",
    pay_monthly_text3: "Intercambiar en segundos",
    faceswap_text5: "tomar cara",
    faceswap_text4: "Agregar imagen de cara",
    pay_monthly_text6: "Desnudo y NSFW",
    faceswap_text7: "intercambiar cara",
    faceswap_text6: "Agregar imagen de destino",
    faceswap_text9: "JPEG/PNG",
    faceswap_text8: "Imagen de la cara",
    normal_video_swap_text1: "Máximo 10 MB, hasta 4 s",
    pay_weekly_text6: "Desnudo y NSFW",
    pay_weekly_text4: "Alta calidad",
    ultra_video_swap_credits_text: "(10 créditos/segundo)",
    ultra_video_size: "Máximo 50 MB",
    ultra_high_quality: "Alta calidad",
    pay_weekly_text5: "Sin marca de agua",
    faceswap_text1: "Alta calidad",
    faceswap_value_button_text1: "Mejora",
    faceswap_text3: "Iniciar sesión",
    faceswap_text2: "Créditos",
    pay_bottom_text1: "Seguridad Protegida por",
    result_text: "Resultado",
    pay_bottom_text2: "ChatUp AI",
    pay_bottom_text3: "Cancelar en cualquier momento",
    tab_image_text: "Imagen",
  },
  cs: {
    pay_yearly_text1: "Roční",
    login_text6: "Stáhnout ON",
    login_text5: "pro výměnu více",
    login_text4: "ChatUp AI",
    message_info2: "Chcete-li vyměnit obličej, přidejte obrázek obličeje.",
    login_text3: "Stáhnout",
    message_info3: "Vyberte prosím produkt k odběru",
    credits_not_enough:
      "Nemáte dostatek kreditů, zakupte si další kredity nebo upgradujte svůj plán.",
    login_text9: "Google Play",
    message_info1: "Nejprve prosím přidejte cílový obrázek.",
    toast_timeout:
      "Server je přetížený a vypršel časový limit výměny obličeje. Zkuste prosím nahrát kratší video.",
    login_text8: "Stáhnout ON",
    login_text7: "App Store",
    select_img_toast_text: "Vyberte prosím obrázek obličeje.",
    pay_yearly_text9: "Nude & NSFW",
    faceswap_text10: "Cílový obrázek",
    pay_yearly_text7: "Vysoká kvalita",
    pay_yearly_text8: "Žádný vodoznak",
    toast_wrong_load: "Chyba při načítání videa. Zkuste prosím jiný soubor.",
    pay_text3: "75% SLEVA",
    pay_yearly_text5: "Kredity",
    faceswap_text13: "Výměna obličeje",
    pay_text2: "Až do",
    pay_yearly_text6: "Výměna během několika sekund",
    pay_text1: "Získejte Premium",
    pay_yearly_text3: "týden",
    faceswap_text11: "JPG / PNG",
    pay_yearly_text4: "účtováno za rok",
    faceswap_text12: "Obrázky, které můžete vyzkoušet",
    target_video_text: "Cílové video",
    ultra_video_length: "Až 1 min",
    pay_text4: "za první předplatné.",
    login_text2: "Abyste si mohli užívat neomezenou výměnu tváře!",
    login_text1: "Přihlaste se",
    no_more_credits:
      "Nejsou žádné další kredity, upgradujte svůj plán, abyste získali více.",
    target_video_duration_text: "Méně než 6s",
    test: "ahoj světe",
    videoswap_text7: "Vyměnit video",
    loading_text1: "Odhadem 30 sekund až 5 minut",
    videoswap_text6: "Přidat cílové video",
    loading_text2:
      "Zůstaňte prosím na aktuální stránce, jinak ztratíte nejlepší výsledek.",
    videoswap_text9: "MP4 / MOV",
    loading_text3: "Urychlit",
    web_request_error:
      "Chyba sítě, zkontrolujte prosím a zkuste to znovu později",
    toast_wrong_fromat: "Nahrajte prosím video ve formátu MP4 nebo MOV.",
    toast_wrong_size: "Nahrajte prosím video menší než 50 MB.",
    mobile_text2: "VELKÝ VÝPRODEJ",
    mobile_text1: "Začněte hned",
    add_video_first_text: "Nejprve prosím přidejte cílové video.",
    toast_wrong_duration: "Nahrajte prosím video kratší než 1 minuta.",
    pay_ultra_video_swap_text6: "Ultra Video Swap",
    ultra_video_swap_text1: "Max 50 MB, až 1 min",
    video_ultra_text1: "Ultra",
    tab_video_text: "Video",
    videoswap_text12: "Videa, která můžete vyzkoušet",
    toast_1: "Vyberte prosím zdrojový obrázek.",
    normal_video_swap_credits_text: "(2 kredity / video)",
    toast_2: "Něco se stalo, zkontrolujte síť a zkuste to znovu.",
    pay_weekly_text2: "Kredit",
    pay_weekly_text3: "Výměna během několika sekund",
    faceswap_value_text1: "250+ kreditů",
    pay_weekly_text1: "Týdně",
    pay_subscribe: "UPSAT",
    faceswap_value_text3: "Žádný vodoznak",
    pay_monthly_text1: "Měsíční",
    faceswap_value_text2: "Přeskočit frontu",
    faceswap_value_text5: "Vysoká kvalita",
    faceswap_value_text4: "Žádné reklamy",
    pay_monthly_text4: "Vysoká kvalita",
    pay_monthly_text5: "Žádný vodoznak",
    faceswap_value_text6: "Žádná omezení",
    pay_monthly_text2: "Kredity",
    pay_monthly_text3: "Výměna během několika sekund",
    faceswap_text5: "Vzít tvář",
    faceswap_text4: "Přidat obrázek tváře",
    pay_monthly_text6: "Nude & NSFW",
    faceswap_text7: "Vyměnit obličej",
    faceswap_text6: "Přidat cílový obrázek",
    faceswap_text9: "JPG / PNG",
    faceswap_text8: "Obrázek obličeje",
    normal_video_swap_text1: "Max 10 MB, až 4 s",
    pay_weekly_text6: "Nude & NSFW",
    pay_weekly_text4: "Vysoká kvalita",
    ultra_video_swap_credits_text: "(10 kreditů za sekundu)",
    ultra_video_size: "Maximálně 50 MB",
    ultra_high_quality: "Vysoká kvalita",
    pay_weekly_text5: "Žádný vodoznak",
    faceswap_text1: "Vysoká kvalita",
    faceswap_value_button_text1: "Upgrade",
    faceswap_text3: "Přihlaste se",
    faceswap_text2: "Kredity",
    pay_bottom_text1: "Zabezpečení Chráněno",
    result_text: "Výsledek",
    pay_bottom_text2: "ChatUp AI",
    pay_bottom_text3: "Kdykoli zrušit",
    tab_image_text: "Obraz",
  },
  ar: {
    pay_yearly_text1: "سنوي",
    login_text6: "تحميل على",
    login_text5: "لمبادلة المزيد",
    login_text4: "ChatUp AI",
    message_info2: "الرجاء إضافة صورة وجه لتبديل الوجه.",
    login_text3: "تحميل",
    message_info3: "الرجاء تحديد منتج للاشتراك",
    credits_not_enough:
      "لا توجد أرصدة كافية، يرجى شراء المزيد من الأرصدة أو ترقية خطتك.",
    login_text9: "جوجل بلاي",
    message_info1: "الرجاء إضافة الصورة المستهدفة أولا.",
    toast_timeout:
      "تم تحميل الخادم بشكل زائد وانتهت مهلة تبديل الوجه. يرجى محاولة تحميل فيديو أقصر.",
    login_text8: "تحميل على",
    login_text7: "متجر التطبيقات",
    select_img_toast_text: "الرجاء اختيار صورة الوجه.",
    pay_yearly_text9: "عارية وNSFW",
    faceswap_text10: "الصورة المستهدفة",
    pay_yearly_text7: "جودة عالية",
    pay_yearly_text8: "لا توجد علامة مائية",
    toast_wrong_load: "حدث خطأ أثناء تحميل الفيديو. الرجاء تجربة ملف آخر.",
    pay_text3: "خصم 75%",
    pay_yearly_text5: "الاعتمادات",
    faceswap_text13: "مبادلة الوجه",
    pay_text2: "حتى",
    pay_yearly_text6: "مبادلة في ثوان",
    pay_text1: "احصل على قسط",
    pay_yearly_text3: "أسبوع",
    faceswap_text11: "جبغ / بابوا نيو غينيا",
    pay_yearly_text4: "فاتورة سنة",
    faceswap_text12: "الصور التي يمكنك تجربتها",
    target_video_text: "فيديو الهدف",
    ultra_video_length: "ما يصل إلى 1 دقيقة",
    pay_text4: "للاشتراك الأول.",
    login_text2: "للاستمتاع بتبديل الوجه غير المحدود!",
    login_text1: "تسجيل الدخول",
    no_more_credits:
      "لم تعد هناك أرصدة أخرى، يرجى ترقية خطتك للحصول على المزيد.",
    target_video_duration_text: "أقل من 6S",
    test: "مرحبا بالعالم",
    videoswap_text7: "مبادلة الفيديو",
    loading_text1: "30 ثانية إلى 5 دقائق مقدرة",
    videoswap_text6: "أضف فيديو الهدف",
    loading_text2: "يرجى البقاء في الصفحة الحالية وإلا ستفقد أفضل نتيجة.",
    videoswap_text9: "MP4 / موف",
    loading_text3: "تسريع",
    web_request_error: "خطأ في الشبكة، يرجى التحقق والمحاولة مرة أخرى لاحقًا",
    toast_wrong_fromat: "يرجى تحميل فيديو بتنسيق MP4 أو MOV.",
    toast_wrong_size: "يرجى تحميل فيديو أصغر من 50 ميغابايت.",
    mobile_text2: "مبيعات كبيرة",
    mobile_text1: "ابدأ الآن",
    add_video_first_text: "يرجى إضافة فيديو مستهدف أولاً.",
    toast_wrong_duration: "يرجى تحميل فيديو أقل من 1 دقيقة.",
    pay_ultra_video_swap_text6: "تبادل الفيديو الترا",
    ultra_video_swap_text1: "الحد الأقصى 50 ميجابايت، حتى دقيقة واحدة",
    video_ultra_text1: "الترا",
    tab_video_text: "فيديو",
    videoswap_text12: "فيديوهات يمكنك تجربتها",
    toast_1: "الرجاء تحديد الصورة المصدر.",
    normal_video_swap_credits_text: "(2 ساعة معتمدة / فيديو)",
    toast_2: "حدث خطأ ما، يرجى التحقق من شبكتك وإعادة المحاولة.",
    pay_weekly_text2: "ائتمان",
    pay_weekly_text3: "مبادلة في ثوان",
    faceswap_value_text1: "250+ ساعة معتمدة",
    pay_weekly_text1: "أسبوعي",
    pay_subscribe: "يشترك",
    faceswap_value_text3: "لا توجد علامة مائية",
    pay_monthly_text1: "شهريا",
    faceswap_value_text2: "تخطي قائمة الانتظار",
    faceswap_value_text5: "جودة عالية",
    faceswap_value_text4: "لا إعلانات",
    pay_monthly_text4: "جودة عالية",
    pay_monthly_text5: "لا توجد علامة مائية",
    faceswap_value_text6: "لا قيود",
    pay_monthly_text2: "الاعتمادات",
    pay_monthly_text3: "مبادلة في ثوان",
    faceswap_text5: "خذ وجهك",
    faceswap_text4: "إضافة صورة الوجه",
    pay_monthly_text6: "عارية وNSFW",
    faceswap_text7: "مبادلة الوجه",
    faceswap_text6: "أضف الصورة المستهدفة",
    faceswap_text9: "جبغ / بابوا نيو غينيا",
    faceswap_text8: "صورة الوجه",
    normal_video_swap_text1: "الحد الأقصى 10 ميجابايت، حتى 4 ثوانٍ",
    pay_weekly_text6: "عارية وNSFW",
    pay_weekly_text4: "جودة عالية",
    ultra_video_swap_credits_text: "(10 ساعات معتمدة / ثانية)",
    ultra_video_size: "ماكس 50 ميجابايت",
    ultra_high_quality: "جودة عالية",
    pay_weekly_text5: "لا توجد علامة مائية",
    faceswap_text1: "جودة عالية",
    faceswap_value_button_text1: "يرقي",
    faceswap_text3: "تسجيل الدخول",
    faceswap_text2: "الاعتمادات",
    pay_bottom_text1: "الأمن محمي بواسطة",
    result_text: "نتيجة",
    pay_bottom_text2: "ChatUp AI",
    pay_bottom_text3: "إلغاء في أي وقت",
    tab_image_text: "صورة",
  },
  vi: {
    pay_yearly_text1: "Hàng năm",
    login_text6: "Tải xuống BẬT",
    login_text5: "để trao đổi thêm",
    login_text4: "AI trò chuyện",
    message_info2: "Vui lòng thêm hình ảnh khuôn mặt để hoán đổi khuôn mặt.",
    login_text3: "Tải xuống",
    message_info3: "Vui lòng chọn sản phẩm để đăng ký",
    credits_not_enough:
      "Không có đủ tín dụng, vui lòng mua thêm tín dụng hoặc nâng cấp gói của bạn.",
    login_text9: "Google Play",
    message_info1: "Vui lòng thêm hình ảnh mục tiêu trước.",
    toast_timeout:
      "Máy chủ bị quá tải và quá trình hoán đổi khuôn mặt đã hết thời gian chờ. Vui lòng thử tải lên một video ngắn hơn.",
    login_text8: "Tải xuống BẬT",
    login_text7: "Cửa hàng ứng dụng",
    select_img_toast_text: "Vui lòng chọn một hình ảnh khuôn mặt.",
    pay_yearly_text9: "Khỏa Thân & NSFW",
    faceswap_text10: "Hình ảnh mục tiêu",
    pay_yearly_text7: "Chất lượng cao",
    pay_yearly_text8: "Không có hình mờ",
    toast_wrong_load: "Lỗi tải video. Vui lòng thử một tập tin khác.",
    pay_text3: "GIẢM GIÁ 75%",
    pay_yearly_text5: "Tín dụng",
    faceswap_text13: "Hoán đổi khuôn mặt",
    pay_text2: "Lên đến",
    pay_yearly_text6: "Trao đổi trong vài giây",
    pay_text1: "Nhận phí bảo hiểm",
    pay_yearly_text3: "tuần",
    faceswap_text11: "JPG / PNG",
    pay_yearly_text4: "thanh toán một năm",
    faceswap_text12: "Hình ảnh bạn có thể thử",
    target_video_text: "Video mục tiêu",
    ultra_video_length: "Lên đến 1 phút",
    pay_text4: "cho lần đăng ký đầu tiên.",
    login_text2: "Để tận hưởng Hoán đổi khuôn mặt không giới hạn!",
    login_text1: "Đăng nhập",
    no_more_credits:
      "Không còn tín dụng nữa, vui lòng nâng cấp gói của bạn để nhận thêm.",
    target_video_duration_text: "Ít hơn 6 giây",
    test: "xin chào thế giới",
    videoswap_text7: "Hoán đổi video",
    loading_text1: "ước tính 30 giây đến 5 phút",
    videoswap_text6: "Thêm video mục tiêu",
    loading_text2:
      "Hãy ở lại trang hiện tại nếu không bạn sẽ mất kết quả tốt nhất.",
    videoswap_text9: "MP4 / MOV",
    loading_text3: "Tăng tốc",
    web_request_error: "Lỗi mạng, vui lòng kiểm tra và thử lại sau",
    toast_wrong_fromat: "Vui lòng tải lên video định dạng MP4 hoặc MOV.",
    toast_wrong_size: "Vui lòng tải lên video có dung lượng nhỏ hơn 50MB.",
    mobile_text2: "BÁN HÀNG LỚN",
    mobile_text1: "Bắt đầu ngay bây giờ",
    add_video_first_text: "Vui lòng thêm video mục tiêu trước.",
    toast_wrong_duration: "Vui lòng tải lên một video ngắn hơn 1 phút.",
    pay_ultra_video_swap_text6: "Trao đổi video siêu",
    ultra_video_swap_text1: "Tối đa 50 MB, Tối đa 1 phút",
    video_ultra_text1: "siêu",
    tab_video_text: "Băng hình",
    videoswap_text12: "Video bạn có thể thử",
    toast_1: "Vui lòng chọn hình ảnh nguồn.",
    normal_video_swap_credits_text: "(2 tín chỉ/video)",
    toast_2: "Đã xảy ra lỗi, vui lòng kiểm tra mạng của bạn và thử lại.",
    pay_weekly_text2: "Tín dụng",
    pay_weekly_text3: "Trao đổi trong vài giây",
    faceswap_value_text1: "Hơn 250 tín chỉ",
    pay_weekly_text1: "hàng tuần",
    pay_subscribe: "ĐẶT MUA",
    faceswap_value_text3: "Không có hình mờ",
    pay_monthly_text1: "hàng tháng",
    faceswap_value_text2: "Bỏ qua hàng đợi",
    faceswap_value_text5: "Chất lượng cao",
    faceswap_value_text4: "Không có quảng cáo",
    pay_monthly_text4: "Chất lượng cao",
    pay_monthly_text5: "Không có hình mờ",
    faceswap_value_text6: "Không hạn chế",
    pay_monthly_text2: "Tín dụng",
    pay_monthly_text3: "Trao đổi trong vài giây",
    faceswap_text5: "Lấy mặt",
    faceswap_text4: "Thêm hình ảnh khuôn mặt",
    pay_monthly_text6: "Khỏa Thân & NSFW",
    faceswap_text7: "Hoán đổi khuôn mặt",
    faceswap_text6: "Thêm hình ảnh mục tiêu",
    faceswap_text9: "JPG / PNG",
    faceswap_text8: "Hình ảnh khuôn mặt",
    normal_video_swap_text1: "Tối đa 10MB, tối đa 4 giây",
    pay_weekly_text6: "Khỏa Thân & NSFW",
    pay_weekly_text4: "Chất lượng cao",
    ultra_video_swap_credits_text: "(10 tín chỉ/giây)",
    ultra_video_size: "Tối đa 50MB",
    ultra_high_quality: "Chất lượng cao",
    pay_weekly_text5: "Không có hình mờ",
    faceswap_text1: "Chất lượng cao",
    faceswap_value_button_text1: "Nâng cấp",
    faceswap_text3: "Đăng nhập",
    faceswap_text2: "Tín dụng",
    pay_bottom_text1: "An ninh được bảo vệ bởi",
    result_text: "Kết quả",
    pay_bottom_text2: "AI trò chuyện",
    pay_bottom_text3: "Hủy bất cứ lúc nào",
    tab_image_text: "Hình ảnh",
  },
  th: {
    pay_yearly_text1: "เป็นประจำทุกปี",
    login_text6: "ดาวน์โหลดบน",
    login_text5: "เพื่อสลับเพิ่มเติม",
    login_text4: "ChatUp AI",
    message_info2: "กรุณาเพิ่มภาพใบหน้าเพื่อสลับใบหน้า",
    login_text3: "ดาวน์โหลด",
    message_info3: "กรุณาเลือกผลิตภัณฑ์ที่จะสมัครสมาชิก",
    credits_not_enough:
      "เครดิตไม่เพียงพอ โปรดซื้อเครดิตเพิ่มหรืออัปเกรดแผนของคุณ",
    login_text9: "Google Play",
    message_info1: "โปรดเพิ่มรูปภาพเป้าหมายก่อน",
    toast_timeout:
      "เซิร์ฟเวอร์โอเวอร์โหลดและการสลับใบหน้าหมดเวลา โปรดลองอัปโหลดวิดีโอที่สั้นลง",
    login_text8: "ดาวน์โหลดบน",
    login_text7: "แอพสโตร์",
    select_img_toast_text: "กรุณาเลือกภาพใบหน้า",
    pay_yearly_text9: "เปลือยและ NSFW",
    faceswap_text10: "รูปภาพเป้าหมาย",
    pay_yearly_text7: "คุณภาพสูง",
    pay_yearly_text8: "ไม่มีลายน้ำ",
    toast_wrong_load: "เกิดข้อผิดพลาดในการโหลดวิดีโอ โปรดลองไฟล์อื่น",
    pay_text3: "ลด 75%",
    pay_yearly_text5: "เครดิต",
    faceswap_text13: "สลับหน้า",
    pay_text2: "ขึ้นไป",
    pay_yearly_text6: "สลับในไม่กี่วินาที",
    pay_text1: "รับพรีเมี่ยม",
    pay_yearly_text3: "สัปดาห์",
    faceswap_text11: "JPG/PNG",
    pay_yearly_text4: "เรียกเก็บเงินหนึ่งปี",
    faceswap_text12: "ภาพที่คุณอาจลอง",
    target_video_text: "วิดีโอเป้าหมาย",
    ultra_video_length: "สูงสุด 1 นาที",
    pay_text4: "สำหรับการสมัครสมาชิกครั้งแรก",
    login_text2: "เพลิดเพลินไปกับ Face Swap ได้ไม่จำกัด!",
    login_text1: "เข้าสู่ระบบ",
    no_more_credits: "ไม่มีเครดิตอีกต่อไป โปรดอัปเกรดแผนของคุณเพื่อรับเพิ่ม",
    target_video_duration_text: "น้อยกว่า 6 วินาที",
    test: "สวัสดีชาวโลก",
    videoswap_text7: "สลับวิดีโอ",
    loading_text1: "ประมาณ 30 วินาทีถึง 5 นาที",
    videoswap_text6: "เพิ่มวิดีโอเป้าหมาย",
    loading_text2:
      "โปรดอยู่ในหน้าปัจจุบัน ไม่เช่นนั้นคุณจะสูญเสียผลลัพธ์ที่ดีที่สุด",
    videoswap_text9: "MP4 / MOV",
    loading_text3: "เร่งความเร็ว",
    web_request_error:
      "เครือข่ายเกิดข้อผิดพลาด โปรดตรวจสอบและลองอีกครั้งในภายหลัง",
    toast_wrong_fromat: "โปรดอัปโหลดวิดีโอในรูปแบบ MP4 หรือ MOV",
    toast_wrong_size: "โปรดอัปโหลดวิดีโอที่มีขนาดเล็กกว่า 50MB",
    mobile_text2: "การขายครั้งใหญ่",
    mobile_text1: "เริ่มเลย",
    add_video_first_text: "โปรดเพิ่มวิดีโอเป้าหมายก่อน",
    toast_wrong_duration: "โปรดอัปโหลดวิดีโอที่สั้นกว่า 1 นาที",
    pay_ultra_video_swap_text6: "สลับวิดีโอแบบพิเศษ",
    ultra_video_swap_text1: "สูงสุด 50MB สูงสุด 1 นาที",
    video_ultra_text1: "อัลตร้า",
    tab_video_text: "วีดีโอ",
    videoswap_text12: "วิดีโอที่คุณอาจลอง",
    toast_1: "กรุณาเลือกภาพต้นฉบับ",
    normal_video_swap_credits_text: "(2 เครดิต /วิดีโอ)",
    toast_2: "มีข้อผิดพลาดเกิดขึ้น โปรดตรวจสอบเครือข่ายของคุณแล้วลองอีกครั้ง",
    pay_weekly_text2: "เครดิต",
    pay_weekly_text3: "สลับในไม่กี่วินาที",
    faceswap_value_text1: "250+ เครดิต",
    pay_weekly_text1: "รายสัปดาห์",
    pay_subscribe: "สมัครสมาชิก",
    faceswap_value_text3: "ไม่มีลายน้ำ",
    pay_monthly_text1: "รายเดือน",
    faceswap_value_text2: "ข้ามคิว",
    faceswap_value_text5: "คุณภาพสูง",
    faceswap_value_text4: "ไม่มีโฆษณา",
    pay_monthly_text4: "คุณภาพสูง",
    pay_monthly_text5: "ไม่มีลายน้ำ",
    faceswap_value_text6: "ไม่มีข้อจำกัด",
    pay_monthly_text2: "เครดิต",
    pay_monthly_text3: "สลับในไม่กี่วินาที",
    faceswap_text5: "เอาหน้า",
    faceswap_text4: "เพิ่มรูปภาพใบหน้า",
    pay_monthly_text6: "เปลือยและ NSFW",
    faceswap_text7: "สลับหน้า.",
    faceswap_text6: "เพิ่มภาพเป้าหมาย",
    faceswap_text9: "JPG/PNG",
    faceswap_text8: "รูปภาพใบหน้า",
    normal_video_swap_text1: "สูงสุด 10MB สูงสุด 4 วินาที",
    pay_weekly_text6: "เปลือยและ NSFW",
    pay_weekly_text4: "คุณภาพสูง",
    ultra_video_swap_credits_text: "(10 หน่วยกิต /วินาที)",
    ultra_video_size: "สูงสุด 50MB",
    ultra_high_quality: "คุณภาพสูง",
    pay_weekly_text5: "ไม่มีลายน้ำ",
    faceswap_text1: "คุณภาพสูง",
    faceswap_value_button_text1: "อัพเกรด",
    faceswap_text3: "เข้าสู่ระบบ",
    faceswap_text2: "เครดิต",
    pay_bottom_text1: "ป้องกันความปลอดภัยโดย",
    result_text: "ผลลัพธ์",
    pay_bottom_text2: "ChatUp AI",
    pay_bottom_text3: "ยกเลิกได้ตลอดเวลา",
    tab_image_text: "ภาพ",
  },
  ja: {
    pay_yearly_text1: "毎年",
    login_text6: "ダウンロードオン",
    login_text5: "さらに交換するには",
    login_text4: "チャットアップAI",
    message_info2: "顔を交換するには顔画像を追加してください。",
    login_text3: "ダウンロード",
    message_info3: "購読する製品を選択してください",
    credits_not_enough:
      "十分なクレジットがありません。さらにクレジットを購入するか、プランをアップグレードしてください。",
    login_text9: "Googleプレイ",
    message_info1: "まず対象の画像を追加してください。",
    toast_timeout:
      "サーバーが過負荷になり、顔の交換がタイムアウトしました。短い動画をアップロードしてみてください。",
    login_text8: "ダウンロードオン",
    login_text7: "アプリストア",
    select_img_toast_text: "顔画像を選択してください。",
    pay_yearly_text9: "ヌード＆NSFW",
    faceswap_text10: "対象画像",
    pay_yearly_text7: "高品質",
    pay_yearly_text8: "透かしなし",
    toast_wrong_load: "ビデオの読み込みエラー。別のファイルを試してください。",
    pay_text3: "75%オフ",
    pay_yearly_text5: "クレジット",
    faceswap_text13: "フェイススワップ",
    pay_text2: "まで",
    pay_yearly_text6: "数秒で交換",
    pay_text1: "プレミアムを入手",
    pay_yearly_text3: "週",
    faceswap_text11: "JPG / PNG",
    pay_yearly_text4: "1年ごとに請求される",
    faceswap_text12: "試してみてもよい画像",
    target_video_text: "対象ビデオ",
    ultra_video_length: "1分まで",
    pay_text4: "最初のサブスクリプションの場合。",
    login_text2: "フェイススワップを無制限に楽しむために！",
    login_text1: "サインイン",
    no_more_credits:
      "クレジットはもうありません。さらに獲得するにはプランをアップグレードしてください。",
    target_video_duration_text: "6秒未満",
    test: "こんにちは世界",
    videoswap_text7: "ビデオを交換する",
    loading_text1: "30秒から5分程度の目安",
    videoswap_text6: "ターゲットビデオを追加",
    loading_text2: "現在のページに留まらないと、最良の結果が得られません。",
    videoswap_text9: "MP4 / MOV",
    loading_text3: "スピードアップ",
    web_request_error:
      "ネットワーク エラー。確認して後でもう一度お試しください。",
    toast_wrong_fromat: "MP4またはMOV形式の動画をアップロードしてください。",
    toast_wrong_size: "50MB 未満の動画をアップロードしてください。",
    mobile_text2: "大セール",
    mobile_text1: "今すぐ始めましょう",
    add_video_first_text: "まず対象の動画を追加してください。",
    toast_wrong_duration: "1分未満の動画をアップロードしてください。",
    pay_ultra_video_swap_text6: "ウルトラビデオスワップ",
    ultra_video_swap_text1: "最大50MB、最大1分",
    video_ultra_text1: "ウルトラ",
    tab_video_text: "ビデオ",
    videoswap_text12: "試してみるとよいビデオ",
    toast_1: "ソース画像を選択してください。",
    normal_video_swap_credits_text: "(2クレジット/ビデオ)",
    toast_2:
      "何か問題が発生しました。ネットワークを確認して再試行してください。",
    pay_weekly_text2: "クレジット",
    pay_weekly_text3: "数秒で交換",
    faceswap_value_text1: "250 クレジット以上",
    pay_weekly_text1: "毎週",
    pay_subscribe: "購読する",
    faceswap_value_text3: "透かしなし",
    pay_monthly_text1: "毎月",
    faceswap_value_text2: "キューをスキップ",
    faceswap_value_text5: "高品質",
    faceswap_value_text4: "広告なし",
    pay_monthly_text4: "高品質",
    pay_monthly_text5: "透かしなし",
    faceswap_value_text6: "制限なし",
    pay_monthly_text2: "クレジット",
    pay_monthly_text3: "数秒で交換",
    faceswap_text5: "顔を取る",
    faceswap_text4: "顔画像を追加",
    pay_monthly_text6: "ヌード＆NSFW",
    faceswap_text7: "顔を交換する",
    faceswap_text6: "ターゲット画像の追加",
    faceswap_text9: "JPG / PNG",
    faceswap_text8: "顔画像",
    normal_video_swap_text1: "最大10MB、最大4秒",
    pay_weekly_text6: "ヌード＆NSFW",
    pay_weekly_text4: "高品質",
    ultra_video_swap_credits_text: "(10クレジット/秒)",
    ultra_video_size: "最大50MB",
    ultra_high_quality: "高品質",
    pay_weekly_text5: "透かしなし",
    faceswap_text1: "高品質",
    faceswap_value_button_text1: "アップグレード",
    faceswap_text3: "サインイン",
    faceswap_text2: "クレジット",
    pay_bottom_text1: "保護されるセキュリティ",
    result_text: "結果",
    pay_bottom_text2: "チャットアップAI",
    pay_bottom_text3: "いつでもキャンセル可能",
    tab_image_text: "画像",
  },
  fa: {
    pay_yearly_text1: "سالانه",
    login_text6: "دانلود روشن",
    login_text5: "برای مبادله بیشتر",
    login_text4: "هوش مصنوعی ChatUp",
    message_info2: "لطفاً یک تصویر چهره برای تعویض چهره اضافه کنید.",
    login_text3: "دانلود کنید",
    message_info3: "لطفاً یک محصول را برای اشتراک انتخاب کنید",
    credits_not_enough:
      "اعتبار کافی وجود ندارد، لطفا اعتبار بیشتری بخرید یا طرح خود را ارتقا دهید.",
    login_text9: "گوگل پلی",
    message_info1: "لطفا ابتدا یک تصویر هدف اضافه کنید.",
    toast_timeout:
      "سرور بیش از حد بارگیری شده است و زمان تعویض چهره تمام شده است. لطفاً سعی کنید ویدیوی کوتاه‌تری آپلود کنید.",
    login_text8: "دانلود روشن",
    login_text7: "اپ استور",
    select_img_toast_text: "لطفا یک تصویر چهره را انتخاب کنید.",
    pay_yearly_text9: "برهنه و NSFW",
    faceswap_text10: "تصویر هدف",
    pay_yearly_text7: "کیفیت بالا",
    pay_yearly_text8: "بدون واترمارک",
    toast_wrong_load: "خطا در بارگیری ویدیو. لطفا فایل دیگری را امتحان کنید.",
    pay_text3: "75% تخفیف",
    pay_yearly_text5: "اعتبارات",
    faceswap_text13: "تعویض چهره",
    pay_text2: "تا",
    pay_yearly_text6: "تعویض در چند ثانیه",
    pay_text1: "حق بیمه را دریافت کنید",
    pay_yearly_text3: "هفته",
    faceswap_text11: "JPG / PNG",
    pay_yearly_text4: "یک سال صورت حساب",
    faceswap_text12: "تصاویری که ممکن است امتحان کنید",
    target_video_text: "ویدیوی هدف",
    ultra_video_length: "تا 1 دقیقه",
    pay_text4: "برای اولین اشتراک",
    login_text2: "برای لذت بردن از تعویض چهره نامحدود!",
    login_text1: "وارد شوید",
    no_more_credits:
      "اعتبار دیگری وجود ندارد، لطفاً طرح خود را برای دریافت بیشتر ارتقا دهید.",
    target_video_duration_text: "کمتر از 6 ثانیه",
    test: "سلام دنیا",
    videoswap_text7: "تعویض ویدیو",
    loading_text1: "30 ثانیه تا 5 دقیقه تخمین زده شده است",
    videoswap_text6: "ویدیوی هدف را اضافه کنید",
    loading_text2:
      "لطفا در صفحه فعلی بمانید وگرنه بهترین نتیجه را از دست خواهید داد.",
    videoswap_text9: "MP4 / MOV",
    loading_text3: "افزایش سرعت",
    web_request_error: "خطای شبکه، لطفاً بررسی کنید و بعداً دوباره امتحان کنید",
    toast_wrong_fromat: "لطفاً یک ویدیو با فرمت MP4 یا MOV آپلود کنید.",
    toast_wrong_size: "لطفا یک ویدیوی کوچکتر از 50 مگابایت آپلود کنید.",
    mobile_text2: "فروش بزرگ",
    mobile_text1: "اکنون شروع کنید",
    add_video_first_text: "لطفا ابتدا یک ویدیوی هدف اضافه کنید.",
    toast_wrong_duration: "لطفا یک ویدیو کوتاهتر از 1 دقیقه آپلود کنید.",
    pay_ultra_video_swap_text6: "تعویض ویدیو فوق العاده",
    ultra_video_swap_text1: "حداکثر 50 مگابایت، حداکثر 1 دقیقه",
    video_ultra_text1: "فوق العاده",
    tab_video_text: "ویدیو",
    videoswap_text12: "ویدیوهایی که ممکن است امتحان کنید",
    toast_1: "لطفا تصویر منبع را انتخاب کنید.",
    normal_video_swap_credits_text: "(2 اعتبار / ویدئو)",
    toast_2:
      "مشکلی رخ داده است، لطفاً شبکه خود را بررسی کرده و دوباره امتحان کنید.",
    pay_weekly_text2: "اعتبار",
    pay_weekly_text3: "تعویض در چند ثانیه",
    faceswap_value_text1: "250+ اعتبار",
    pay_weekly_text1: "هفتگی",
    pay_subscribe: "مشترک شوید",
    faceswap_value_text3: "بدون واترمارک",
    pay_monthly_text1: "ماهانه",
    faceswap_value_text2: "رد شدن از صف",
    faceswap_value_text5: "کیفیت بالا",
    faceswap_value_text4: "بدون تبلیغات",
    pay_monthly_text4: "کیفیت بالا",
    pay_monthly_text5: "بدون واترمارک",
    faceswap_value_text6: "بدون محدودیت",
    pay_monthly_text2: "اعتبارات",
    pay_monthly_text3: "تعویض در چند ثانیه",
    faceswap_text5: "صورت بگیر",
    faceswap_text4: "اضافه کردن تصویر چهره",
    pay_monthly_text6: "برهنه و NSFW",
    faceswap_text7: "تعویض چهره",
    faceswap_text6: "افزودن تصویر هدف",
    faceswap_text9: "JPG / PNG",
    faceswap_text8: "تصویر چهره",
    normal_video_swap_text1: "حداکثر 10 مگابایت، حداکثر 4 ثانیه",
    pay_weekly_text6: "برهنه و NSFW",
    pay_weekly_text4: "کیفیت بالا",
    ultra_video_swap_credits_text: "(10 واحد در ثانیه)",
    ultra_video_size: "حداکثر 50 مگابایت",
    ultra_high_quality: "کیفیت بالا",
    pay_weekly_text5: "بدون واترمارک",
    faceswap_text1: "کیفیت بالا",
    faceswap_value_button_text1: "ارتقا دهید",
    faceswap_text3: "وارد شوید",
    faceswap_text2: "اعتبارات",
    pay_bottom_text1: "امنیت محافظت شده توسط",
    result_text: "نتیجه",
    pay_bottom_text2: "هوش مصنوعی ChatUp",
    pay_bottom_text3: "هر زمان خواستید لغو کنید",
    tab_image_text: "تصویر",
  },
  pl: {
    pay_yearly_text1: "Rocznie",
    login_text6: "Pobierz WŁ",
    login_text5: "aby wymienić więcej",
    login_text4: "ChatUp AI",
    message_info2: "Dodaj obraz twarzy, aby zamienić twarz.",
    login_text3: "Pobierać",
    message_info3: "Wybierz produkt do subskrypcji",
    credits_not_enough:
      "Brak wystarczającej liczby kredytów. Kup więcej kredytów lub uaktualnij swój plan.",
    login_text9: "Google Play",
    message_info1: "Najpierw dodaj obraz docelowy.",
    toast_timeout:
      "Serwer jest przeciążony i upłynął limit czasu wymiany twarzy. Spróbuj przesłać krótszy film.",
    login_text8: "Pobierz WŁ",
    login_text7: "Sklep z aplikacjami",
    select_img_toast_text: "Wybierz obraz twarzy.",
    pay_yearly_text9: "Nago i NSFW",
    faceswap_text10: "Obraz docelowy",
    pay_yearly_text7: "Wysoka jakość",
    pay_yearly_text8: "Brak znaku wodnego",
    toast_wrong_load:
      "Błąd podczas ładowania wideo. Spróbuj użyć innego pliku.",
    pay_text3: "75% ZNIŻKI",
    pay_yearly_text5: "Kredyty",
    faceswap_text13: "Zamiana twarzy",
    pay_text2: "Aż do",
    pay_yearly_text6: "Zamień w kilka sekund",
    pay_text1: "Uzyskaj wersję Premium",
    pay_yearly_text3: "tydzień",
    faceswap_text11: "JPG/PNG",
    pay_yearly_text4: "rozliczane rok",
    faceswap_text12: "Obrazy, które możesz wypróbować",
    target_video_text: "Docelowe wideo",
    ultra_video_length: "Do 1 minuty",
    pay_text4: "za pierwszą subskrypcję.",
    login_text2: "Aby cieszyć się nieograniczoną zamianą twarzy!",
    login_text1: "Zalogować się",
    no_more_credits:
      "Nie ma więcej kredytów. Uaktualnij swój plan, aby uzyskać więcej.",
    target_video_duration_text: "Mniej niż 6 s",
    test: "witaj świecie",
    videoswap_text7: "Zamień wideo",
    loading_text1: "Szacowany czas od 30 sekund do 5 minut",
    videoswap_text6: "Dodaj docelowy film",
    loading_text2:
      "Pozostań na bieżącej stronie, w przeciwnym razie utracisz najlepszy wynik.",
    videoswap_text9: "MP4 / MOV",
    loading_text3: "Przyśpieszyć",
    web_request_error: "Błąd sieci. Sprawdź i spróbuj ponownie później",
    toast_wrong_fromat: "Prześlij film w formacie MP4 lub MOV.",
    toast_wrong_size: "Prześlij film mniejszy niż 50 MB.",
    mobile_text2: "DUŻA WYPRZEDAŻ",
    mobile_text1: "Zacznij teraz",
    add_video_first_text: "Najpierw dodaj docelowy film.",
    toast_wrong_duration: "Prześlij film krótszy niż 1 minuta.",
    pay_ultra_video_swap_text6: "Ultra wymiana wideo",
    ultra_video_swap_text1: "Maks. 50 MB, do 1 minuty",
    video_ultra_text1: "Ultra",
    tab_video_text: "Wideo",
    videoswap_text12: "Filmy, które możesz wypróbować",
    toast_1: "Wybierz obraz źródłowy.",
    normal_video_swap_credits_text: "(2 napisy / wideo)",
    toast_2: "Stało się coś złego. Sprawdź swoją sieć i spróbuj ponownie.",
    pay_weekly_text2: "Kredyt",
    pay_weekly_text3: "Zamień w kilka sekund",
    faceswap_value_text1: "Ponad 250 kredytów",
    pay_weekly_text1: "Tygodnik",
    pay_subscribe: "SUBSKRYBOWAĆ",
    faceswap_value_text3: "Brak znaku wodnego",
    pay_monthly_text1: "Miesięczny",
    faceswap_value_text2: "Pomiń kolejkę",
    faceswap_value_text5: "Wysoka jakość",
    faceswap_value_text4: "Brak reklam",
    pay_monthly_text4: "Wysoka jakość",
    pay_monthly_text5: "Brak znaku wodnego",
    faceswap_value_text6: "Żadnych ograniczeń",
    pay_monthly_text2: "Kredyty",
    pay_monthly_text3: "Zamień w kilka sekund",
    faceswap_text5: "Przyjmij twarz",
    faceswap_text4: "Dodaj obraz twarzy",
    pay_monthly_text6: "Nago i NSFW",
    faceswap_text7: "Zamień twarz",
    faceswap_text6: "Dodaj obraz docelowy",
    faceswap_text9: "JPG/PNG",
    faceswap_text8: "Obraz twarzy",
    normal_video_swap_text1: "Maks. 10 MB, do 4 s",
    pay_weekly_text6: "Nago i NSFW",
    pay_weekly_text4: "Wysoka jakość",
    ultra_video_swap_credits_text: "(10 kredytów na sekundę)",
    ultra_video_size: "Maks. 50MB",
    ultra_high_quality: "Wysoka jakość",
    pay_weekly_text5: "Brak znaku wodnego",
    faceswap_text1: "Wysoka jakość",
    faceswap_value_button_text1: "Aktualizacja",
    faceswap_text3: "Zalogować się",
    faceswap_text2: "Kredyty",
    pay_bottom_text1: "Bezpieczeństwo Chronione przez",
    result_text: "Wynik",
    pay_bottom_text2: "ChatUp AI",
    pay_bottom_text3: "Anuluj w dowolnym momencie",
    tab_image_text: "Obraz",
  },
  da: {
    pay_yearly_text1: "Årligt",
    login_text6: "Download TIL",
    login_text5: "for at bytte mere",
    login_text4: "ChatUp AI",
    message_info2: "Tilføj venligst et ansigtsbillede for at bytte ansigt.",
    login_text3: "Download",
    message_info3: "Vælg venligst et produkt for at abonnere",
    credits_not_enough:
      "Ikke nok kreditter, køb venligst flere kreditter eller opgrader din plan.",
    login_text9: "Google Play",
    message_info1: "Tilføj venligst et målbillede først.",
    toast_timeout:
      "Serveren er overbelastet, og ansigtsbyttet fik timeout. Prøv at uploade en kortere video.",
    login_text8: "Download TIL",
    login_text7: "App Store",
    select_img_toast_text: "Vælg venligst et ansigtsbillede.",
    pay_yearly_text9: "Nøgen & NSFW",
    faceswap_text10: "Målbillede",
    pay_yearly_text7: "Høj kvalitet",
    pay_yearly_text8: "Intet vandmærke",
    toast_wrong_load:
      "Fejl ved indlæsning af video. Prøv venligst en anden fil.",
    pay_text3: "75 % RABAT",
    pay_yearly_text5: "Credits",
    faceswap_text13: "Face Swap",
    pay_text2: "Op til",
    pay_yearly_text6: "Skift på få sekunder",
    pay_text1: "Få Premium",
    pay_yearly_text3: "uge",
    faceswap_text11: "JPG / PNG",
    pay_yearly_text4: "faktureres et år",
    faceswap_text12: "Billeder du kan prøve",
    target_video_text: "Målvideo",
    ultra_video_length: "Op til 1 min",
    pay_text4: "for det første abonnement.",
    login_text2: "For at nyde ubegrænset Face Swap!",
    login_text1: "Log ind",
    no_more_credits:
      "Der er ikke flere kreditter. Opgrader venligst din plan for at få flere.",
    target_video_duration_text: "Mindre end 6s",
    test: "hej verden",
    videoswap_text7: "Byt video",
    loading_text1: "30 sekunder til 5 minutter anslået",
    videoswap_text6: "Tilføj målvideo",
    loading_text2:
      "Bliv venligst på den aktuelle side, ellers mister du det bedste resultat.",
    videoswap_text9: "MP4 / MOV",
    loading_text3: "Sæt fart på",
    web_request_error: "Netværksfejl. Tjek venligst og prøv igen senere",
    toast_wrong_fromat: "Upload venligst en video i MP4- eller MOV-format.",
    toast_wrong_size: "Upload venligst en video, der er mindre end 50 MB.",
    mobile_text2: "STORT SALG",
    mobile_text1: "Start nu",
    add_video_first_text: "Tilføj venligst en målvideo først.",
    toast_wrong_duration:
      "Upload venligst en video, der er kortere end 1 minut.",
    pay_ultra_video_swap_text6: "Ultra Video Swap",
    ultra_video_swap_text1: "Max 50 MB, op til 1 min",
    video_ultra_text1: "Ultra",
    tab_video_text: "Video",
    videoswap_text12: "Videoer du kan prøve",
    toast_1: "Vælg venligst kildebilledet.",
    normal_video_swap_credits_text: "(2 kreditter/video)",
    toast_2: "Der skete noget galt. Tjek venligst dit netværk og prøv igen.",
    pay_weekly_text2: "Kredit",
    pay_weekly_text3: "Skift på få sekunder",
    faceswap_value_text1: "250+ kreditter",
    pay_weekly_text1: "Ugentlig",
    pay_subscribe: "ABONNER",
    faceswap_value_text3: "Intet vandmærke",
    pay_monthly_text1: "Månedlig",
    faceswap_value_text2: "Spring kø over",
    faceswap_value_text5: "Høj kvalitet",
    faceswap_value_text4: "Ingen annoncer",
    pay_monthly_text4: "Høj kvalitet",
    pay_monthly_text5: "Intet vandmærke",
    faceswap_value_text6: "Ingen begrænsninger",
    pay_monthly_text2: "Credits",
    pay_monthly_text3: "Skift på få sekunder",
    faceswap_text5: "Tag ansigt",
    faceswap_text4: "Tilføj ansigtsbillede",
    pay_monthly_text6: "Nøgen & NSFW",
    faceswap_text7: "Skift ansigt",
    faceswap_text6: "Tilføj målbillede",
    faceswap_text9: "JPG / PNG",
    faceswap_text8: "Ansigtsbillede",
    normal_video_swap_text1: "Max 10 MB, op til 4 sek",
    pay_weekly_text6: "Nøgen & NSFW",
    pay_weekly_text4: "Høj kvalitet",
    ultra_video_swap_credits_text: "(10 kreditter/sekund)",
    ultra_video_size: "Max 50 MB",
    ultra_high_quality: "Høj kvalitet",
    pay_weekly_text5: "Intet vandmærke",
    faceswap_text1: "Høj kvalitet",
    faceswap_value_button_text1: "Opgrader",
    faceswap_text3: "Log ind",
    faceswap_text2: "Credits",
    pay_bottom_text1: "Sikkerhed Beskyttet af",
    result_text: "Resultat",
    pay_bottom_text2: "ChatUp AI",
    pay_bottom_text3: "Annuller når som helst",
    tab_image_text: "Billede",
  },
  he: {
    pay_yearly_text1: "שְׁנָתִי",
    login_text6: "הורד ON",
    login_text5: "כדי להחליף עוד",
    login_text4: "ChatUp AI",
    message_info2: "אנא הוסף תמונת פנים כדי להחליף פנים.",
    login_text3: "הורד",
    message_info3: "אנא בחר מוצר להרשמה",
    credits_not_enough:
      "אין מספיק קרדיטים, נא לרכוש זיכויים נוספים או לשדרג את התוכנית שלך.",
    login_text9: "Google Play",
    message_info1: "הוסף תחילה תמונת יעד.",
    toast_timeout:
      "השרת עמוס יתר על המידה ותם הזמן הקצוב להחלפת הפנים. נסה להעלות סרטון קצר יותר.",
    login_text8: "הורד ON",
    login_text7: "App Store",
    select_img_toast_text: "אנא בחר תמונת פנים.",
    pay_yearly_text9: "עירום ו-NSFW",
    faceswap_text10: "תמונת יעד",
    pay_yearly_text7: "איכות גבוהה",
    pay_yearly_text8: "אין סימן מים",
    toast_wrong_load: "שגיאה בטעינת הסרטון. אנא נסה קובץ אחר.",
    pay_text3: "75% הנחה",
    pay_yearly_text5: "קרדיטים",
    faceswap_text13: "החלפת פנים",
    pay_text2: "עד",
    pay_yearly_text6: "החלפה תוך שניות",
    pay_text1: "קבל פרימיום",
    pay_yearly_text3: "שָׁבוּעַ",
    faceswap_text11: "JPG / PNG",
    pay_yearly_text4: "מחויב בשנה",
    faceswap_text12: "תמונות שאתה יכול לנסות",
    target_video_text: "סרטון יעד",
    ultra_video_length: "עד 1 דקות",
    pay_text4: "עבור המנוי הראשון.",
    login_text2: "כדי ליהנות מ-Face Swap ללא הגבלה!",
    login_text1: "היכנס",
    no_more_credits: "אין יותר קרדיטים, אנא שדרג את התוכנית שלך כדי לקבל יותר.",
    target_video_duration_text: "פחות מ-6 שניות",
    test: "שלום עולם",
    videoswap_text7: "החלפת וידאו",
    loading_text1: "30 שניות עד 5 דקות משוער",
    videoswap_text6: "הוסף סרטון יעד",
    loading_text2: "אנא הישאר בדף הנוכחי אחרת תאבד את התוצאה הטובה ביותר.",
    videoswap_text9: "MP4 / MOV",
    loading_text3: "לְהַאִיץ",
    web_request_error: "שגיאת רשת, אנא בדוק ונסה שוב מאוחר יותר",
    toast_wrong_fromat: "נא להעלות סרטון בפורמט MP4 או MOV.",
    toast_wrong_size: "נא להעלות סרטון קטן מ-50MB.",
    mobile_text2: "מכירות גדולות",
    mobile_text1: "התחל עכשיו",
    add_video_first_text: "הוסף קודם סרטון יעד.",
    toast_wrong_duration: "נא להעלות סרטון קצר מדקה אחת.",
    pay_ultra_video_swap_text6: "החלפת וידאו אולטרה",
    ultra_video_swap_text1: "מקסימום 50MB, עד 1 דקות",
    video_ultra_text1: "אוּלְטרָה",
    tab_video_text: "וִידֵאוֹ",
    videoswap_text12: "סרטונים שאתה יכול לנסות",
    toast_1: "אנא בחר את תמונת המקור.",
    normal_video_swap_credits_text: "(2 קרדיטים/סרטון)",
    toast_2: "משהו לא בסדר קרה, אנא בדוק את הרשת שלך ונסה שוב.",
    pay_weekly_text2: "אַשׁרַאי",
    pay_weekly_text3: "החלפה תוך שניות",
    faceswap_value_text1: "250+ קרדיטים",
    pay_weekly_text1: "שְׁבוּעִי",
    pay_subscribe: "הירשם",
    faceswap_value_text3: "אין סימן מים",
    pay_monthly_text1: "יַרחוֹן",
    faceswap_value_text2: "דלג על תור",
    faceswap_value_text5: "איכות גבוהה",
    faceswap_value_text4: "אין מודעות",
    pay_monthly_text4: "איכות גבוהה",
    pay_monthly_text5: "אין סימן מים",
    faceswap_value_text6: "אין הגבלות",
    pay_monthly_text2: "קרדיטים",
    pay_monthly_text3: "החלפה תוך שניות",
    faceswap_text5: "קח פנים",
    faceswap_text4: "הוסף תמונת פנים",
    pay_monthly_text6: "עירום ו-NSFW",
    faceswap_text7: "להחליף פנים",
    faceswap_text6: "הוסף תמונת יעד",
    faceswap_text9: "JPG / PNG",
    faceswap_text8: "תמונת פנים",
    normal_video_swap_text1: "מקסימום 10MB, עד 4 שניות",
    pay_weekly_text6: "עירום ו-NSFW",
    pay_weekly_text4: "איכות גבוהה",
    ultra_video_swap_credits_text: "(10 קרדיטים לשנייה)",
    ultra_video_size: "מקסימום 50MB",
    ultra_high_quality: "איכות גבוהה",
    pay_weekly_text5: "אין סימן מים",
    faceswap_text1: "איכות גבוהה",
    faceswap_value_button_text1: "שדרוג",
    faceswap_text3: "היכנס",
    faceswap_text2: "קרדיטים",
    pay_bottom_text1: "אבטחה מוגנת על ידי",
    result_text: "תוֹצָאָה",
    pay_bottom_text2: "ChatUp AI",
    pay_bottom_text3: "בטל בכל עת",
    tab_image_text: "תְמוּנָה",
  },
  nl: {
    pay_yearly_text1: "Jaarlijks",
    login_text6: "Downloaden AAN",
    login_text5: "om meer te ruilen",
    login_text4: "ChatUp-AI",
    message_info2:
      "Voeg een gezichtsafbeelding toe om van gezicht te wisselen.",
    login_text3: "Downloaden",
    message_info3: "Selecteer een product waarop u zich wilt abonneren",
    credits_not_enough:
      "Niet genoeg credits. Koop meer credits of upgrade uw abonnement.",
    login_text9: "Google Spelen",
    message_info1: "Voeg eerst een doelafbeelding toe.",
    toast_timeout:
      "De server is overbelast en er is een time-out opgetreden voor de face-swap. Probeer een kortere video te uploaden.",
    login_text8: "Downloaden AAN",
    login_text7: "App Store",
    select_img_toast_text: "Selecteer een gezichtsafbeelding.",
    pay_yearly_text9: "Naakt en NSFW",
    faceswap_text10: "Doelafbeelding",
    pay_yearly_text7: "Hoge kwaliteit",
    pay_yearly_text8: "Geen watermerk",
    toast_wrong_load:
      "Fout bij het laden van video. Probeer een ander bestand.",
    pay_text3: "75% KORTING",
    pay_yearly_text5: "Kredieten",
    faceswap_text13: "Gezicht wisselen",
    pay_text2: "Tot",
    pay_yearly_text6: "Wissel binnen enkele seconden",
    pay_text1: "Ontvang Premium",
    pay_yearly_text3: "week",
    faceswap_text11: "JPG/PNG",
    pay_yearly_text4: "per jaar gefactureerd",
    faceswap_text12: "Afbeeldingen die u kunt proberen",
    target_video_text: "Doelvideo",
    ultra_video_length: "Tot 1min",
    pay_text4: "voor het eerste abonnement.",
    login_text2: "Om onbeperkt te genieten van Face Swap!",
    login_text1: "Inloggen",
    no_more_credits:
      "Er zijn geen credits meer. Upgrade uw abonnement om meer te krijgen.",
    target_video_duration_text: "Minder dan 6s",
    test: "hallo wereld",
    videoswap_text7: "Wissel video uit",
    loading_text1: "Geschatte duur van 30 seconden tot 5 minuten",
    videoswap_text6: "Doelvideo toevoegen",
    loading_text2:
      "Blijf op de huidige pagina, anders verliest u het beste resultaat.",
    videoswap_text9: "MP4/MOV",
    loading_text3: "Versnellen",
    web_request_error:
      "Netwerkfout. Controleer dit en probeer het later opnieuw",
    toast_wrong_fromat: "Upload een video in MP4- of MOV-formaat.",
    toast_wrong_size: "Upload een video die kleiner is dan 50 MB.",
    mobile_text2: "GROTE VERKOOP",
    mobile_text1: "Begin nu",
    add_video_first_text: "Voeg eerst een doelvideo toe.",
    toast_wrong_duration: "Upload een video die korter is dan 1 minuut.",
    pay_ultra_video_swap_text6: "Ultravideowissel",
    ultra_video_swap_text1: "Maximaal 50 MB, maximaal 1 minuut",
    video_ultra_text1: "Ultra",
    tab_video_text: "Video",
    videoswap_text12: "Video's die u kunt proberen",
    toast_1: "Selecteer de bronafbeelding.",
    normal_video_swap_credits_text: "(2 credits/video)",
    toast_2:
      "Er is iets misgegaan. Controleer uw netwerk en probeer het opnieuw.",
    pay_weekly_text2: "Credit",
    pay_weekly_text3: "Wissel binnen enkele seconden",
    faceswap_value_text1: "250+ studiepunten",
    pay_weekly_text1: "Wekelijks",
    pay_subscribe: "ABONNEREN",
    faceswap_value_text3: "Geen watermerk",
    pay_monthly_text1: "Maandelijks",
    faceswap_value_text2: "Wachtrij overslaan",
    faceswap_value_text5: "Hoge kwaliteit",
    faceswap_value_text4: "Geen advertenties",
    pay_monthly_text4: "Hoge kwaliteit",
    pay_monthly_text5: "Geen watermerk",
    faceswap_value_text6: "Geen beperkingen",
    pay_monthly_text2: "Kredieten",
    pay_monthly_text3: "Wissel binnen enkele seconden",
    faceswap_text5: "Neem gezicht",
    faceswap_text4: "Gezichtsafbeelding toevoegen",
    pay_monthly_text6: "Naakt en NSFW",
    faceswap_text7: "Wissel van gezicht",
    faceswap_text6: "Doelafbeelding toevoegen",
    faceswap_text9: "JPG/PNG",
    faceswap_text8: "Gezichtsafbeelding",
    normal_video_swap_text1: "Maximaal 10 MB, maximaal 4 seconden",
    pay_weekly_text6: "Naakt en NSFW",
    pay_weekly_text4: "Hoge kwaliteit",
    ultra_video_swap_credits_text: "(10 credits /seconde)",
    ultra_video_size: "Maximaal 50 MB",
    ultra_high_quality: "Hoge kwaliteit",
    pay_weekly_text5: "Geen watermerk",
    faceswap_text1: "Hoge kwaliteit",
    faceswap_value_button_text1: "Upgraden",
    faceswap_text3: "Inloggen",
    faceswap_text2: "Kredieten",
    pay_bottom_text1: "Beveiliging Beschermd door",
    result_text: "Resultaat",
    pay_bottom_text2: "ChatUp-AI",
    pay_bottom_text3: "Annuleer op elk gewenst moment",
    tab_image_text: "Afbeelding",
  },
  tr: {
    pay_yearly_text1: "Yıllık",
    login_text6: "AÇIK İndir",
    login_text5: "Daha Fazla Takas Yapmak için",
    login_text4: "Sohbet Yapay Zekası",
    message_info2: "Yüzü değiştirmek için lütfen bir yüz resmi ekleyin.",
    login_text3: "İndirmek",
    message_info3: "Lütfen abone olmak için bir ürün seçin",
    credits_not_enough:
      "Yeterli kredi yok, lütfen daha fazla kredi satın alın veya planınızı yükseltin.",
    login_text9: "Google Play",
    message_info1: "Lütfen önce bir hedef resim ekleyin.",
    toast_timeout:
      "Sunucu aşırı yüklendi ve yüz değiştirme zaman aşımına uğradı. Lütfen daha kısa bir video yüklemeyi deneyin.",
    login_text8: "AÇIK İndir",
    login_text7: "Uygulama Mağazası",
    select_img_toast_text: "Lütfen bir yüz resmi seçin.",
    pay_yearly_text9: "Çıplak ve NSFW",
    faceswap_text10: "Hedef Resim",
    pay_yearly_text7: "Yüksek Kalite",
    pay_yearly_text8: "Filigran Yok",
    toast_wrong_load:
      "Video yüklenirken hata oluştu. Lütfen başka bir dosya deneyin.",
    pay_text3: "%75 İNDİRİM",
    pay_yearly_text5: "Kredi",
    faceswap_text13: "Yüz Değiştirme",
    pay_text2: "kadar",
    pay_yearly_text6: "Saniyeler içinde değiştirin",
    pay_text1: "Premium'u Alın",
    pay_yearly_text3: "hafta",
    faceswap_text11: "JPG / PNG",
    pay_yearly_text4: "yıllık faturalandırılır",
    faceswap_text12: "Deneyebileceğiniz görseller",
    target_video_text: "Hedef Video",
    ultra_video_length: "1 dakikaya kadar",
    pay_text4: "ilk abonelik için.",
    login_text2: "Sınırsız Yüz Değiştirmenin tadını çıkarmak için!",
    login_text1: "Kayıt olmak",
    no_more_credits:
      "Artık kredi yok, daha fazlasını almak için lütfen planınızı yükseltin.",
    target_video_duration_text: "6 saniyeden az",
    test: "Selam Dünya",
    videoswap_text7: "Videoyu Değiştir",
    loading_text1: "Tahmini 30 saniye ile 5 dakika arası",
    videoswap_text6: "Hedef Video Ekle",
    loading_text2:
      "Lütfen mevcut sayfada kalın, aksi takdirde en iyi sonucu kaybedersiniz.",
    videoswap_text9: "MP4 / MOV",
    loading_text3: "Hızlandır",
    web_request_error:
      "Ağ hatası, lütfen kontrol edip daha sonra tekrar deneyin",
    toast_wrong_fromat: "Lütfen MP4 veya MOV formatında bir video yükleyin.",
    toast_wrong_size: "Lütfen 50 MB'tan küçük bir video yükleyin.",
    mobile_text2: "BÜYÜK SATIŞLAR",
    mobile_text1: "Şimdi Başla",
    add_video_first_text: "Lütfen önce bir hedef video ekleyin.",
    toast_wrong_duration: "Lütfen 1 dakikadan kısa bir video yükleyiniz.",
    pay_ultra_video_swap_text6: "Ultra Video Değiştirme",
    ultra_video_swap_text1: "Maksimum 50 MB, 1 dakikaya kadar",
    video_ultra_text1: "Ultra",
    tab_video_text: "Video",
    videoswap_text12: "Deneyebileceğiniz videolar",
    toast_1: "Lütfen kaynak görseli seçin.",
    normal_video_swap_credits_text: "(2 kredi /video)",
    toast_2: "Bir sorun oluştu. Lütfen ağınızı kontrol edip yeniden deneyin.",
    pay_weekly_text2: "Kredi",
    pay_weekly_text3: "Saniyeler içinde değiştirin",
    faceswap_value_text1: "250+ kredi",
    pay_weekly_text1: "Haftalık",
    pay_subscribe: "ABONE",
    faceswap_value_text3: "Filigran Yok",
    pay_monthly_text1: "Aylık",
    faceswap_value_text2: "Sırayı Atla",
    faceswap_value_text5: "Yüksek Kalite",
    faceswap_value_text4: "Reklam Yok",
    pay_monthly_text4: "Yüksek Kalite",
    pay_monthly_text5: "Filigran Yok",
    faceswap_value_text6: "Kısıtlama Yok",
    pay_monthly_text2: "Kredi",
    pay_monthly_text3: "Saniyeler içinde değiştirin",
    faceswap_text5: "Yüzleş",
    faceswap_text4: "Yüz Resmi Ekle",
    pay_monthly_text6: "Çıplak ve NSFW",
    faceswap_text7: "Yüzü değiştir",
    faceswap_text6: "Hedef Resim Ekle",
    faceswap_text9: "JPG / PNG",
    faceswap_text8: "Yüz Resmi",
    normal_video_swap_text1: "Maksimum 10 MB, 4 saniyeye kadar",
    pay_weekly_text6: "Çıplak ve NSFW",
    pay_weekly_text4: "Yüksek Kalite",
    ultra_video_swap_credits_text: "(10 kredi/saniye)",
    ultra_video_size: "Maksimum 50 MB",
    ultra_high_quality: "Yüksek Kalite",
    pay_weekly_text5: "Filigran Yok",
    faceswap_text1: "Yüksek Kalite",
    faceswap_value_button_text1: "Güncelleme",
    faceswap_text3: "Kayıt olmak",
    faceswap_text2: "Kredi",
    pay_bottom_text1: "Güvenlik Tarafından Korunmaktadır",
    result_text: "Sonuç",
    pay_bottom_text2: "Sohbet Yapay Zekası",
    pay_bottom_text3: "İstediğiniz zaman iptal edin",
    tab_image_text: "Resim",
  },
};

export default messages;
